import * as yup from "yup";
export const getValidationSchema = (isRequired) => {
  return yup.object().shape({
    role: yup.string().required("Role is required"),
    nickname: yup.string().when("role", {
      is: (value) => value !== "client",
      then: yup
        .string()
        .required("Nickname is required")
        .trim() 
        .min(3, "Nickname must be at least 3 characters")
        .max(50, "Nickname must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
  
    whatsAppPhoneNumber: yup
      .string()
      .required("Whatsapp Phone Number is required")
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        "Invalid WhatsApp phone number. It should start with a '+' followed by your country code and number, e.g., +201034567890."
      ),
  
    experienceYears: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role !== "client" && studyYear === "Graduate",
      then: yup
        .string()
        .required("Experience Years is required")
        .matches(/^\d+$/, "Experience Years must be a number")
        .test(
          "is-valid-range",
          "Experience Years must be between 0 and 30",
          (value) => {
            const num = parseInt(value, 10);
            return num >= 0 && num <= 30;
          }
        ),
      otherwise: yup.string().nullable(),
    }),
    university: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role !== "client" && studyYear !== "Graduate",
      then: yup
        .string()
        .required("University is required")
        .trim()
        .min(3, "University name must be at least 3 characters")
        .max(50, "University name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
  
    faculty: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role !== "client" && studyYear !== "Graduate",
      then: yup
        .string()
        .required("Faculty is required")
        .trim()
        .min(3, "Faculty name must be at least 3 characters")
        .max(50, "Faculty name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
  
    studyYear: yup
      .string()
      .when("role", {
        is: (value) => value !== "client",
        then: yup.string().required("Study Year is required"),
      }),
    // interestFields: yup.array().min(1, 'At least one interest field is required'),
    companyName: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role === "client" || studyYear === "Graduate",
      then: yup
        .string()
        .required("Company Name is required")
        .trim()
        .min(3, "Company name must be at least 3 characters")
        .max(50, "Company name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
  
    jobState: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => studyYear === "Graduate" && role !== "client",
      then: yup.string().required("Job State is required"),
      otherwise: yup.string().optional().nullable(),
    }),
    githubHandle: yup.string().when("role", {
      is: (value) => value !== "client",
      then: yup
        .string()
        .required("Github Handle is required")
        .trim()
        .min(3, "Github handle must be at least 3 characters")
        .max(50, "Github handle must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
    country: yup
      .string()
      .required("Country is required")
      .trim()
      .min(3, "Country must be at least 3 characters")
      .max(50, "Country must be at most 50 characters"),
    city: yup
      .string()
      .required("City is required")
      .trim()
      .min(3, "City must be at least 3 characters")
      .max(50, "City must be at most 50 characters"),
    interestFields: yup
      .array()
      .min(1, "At least one interest field is required")
      .required("Interest field is required"),
      terms: isRequired
      ? yup.boolean().oneOf([true], 'You must agree to the terms of service').required('You must agree to the terms of service')
      : yup.boolean(),
  });
}

export const handleErrorMessage = (errorMessage) => {
  const match = errorMessage.match(/unique constraint "([^"]+)"/);

  if (match && match[1]) {
    const uniqueConstraint = match[1];
    if (uniqueConstraint === "users_phonenumber_unique") {
      return "WhatsApp phone number must be unique";
    } else if (uniqueConstraint === "users_handle_unique") {
      return "GitHub Handle and Nickname must be unique";
    } else {
      return "Can't save user now.";
    }
  } else {
    console.log("Unique constraint not found.");
  }
};


export const roleData = (data)=>{
  const {role, nickname , whatsAppPhoneNumber , university , faculty , studyYear , interestFields , companyName, jobState , experienceYears , githubHandle , country , city }= data;
  const interest = interestFields.join(', ');
  let newData =  { role , whatsAppPhoneNumber , country , city , interestFields:interest };
  if(role === "client"){
    newData = {...newData , companyName};
  }else if(studyYear === "Graduate"){
    newData = {...newData , studyYear , companyName, jobState , experienceYears:+experienceYears , nickname , githubHandle};
  }else{
    newData = {...newData ,studyYear , university , faculty, nickname , githubHandle};
  }
  console.log(newData)
  return newData;
}