import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PaginationFiltration from "./components/pagination-filtration";
import Arrow from "./components/Arrow";
import searchTable, { sortTable } from "./utils";
import useMySubmissionsAPI from "../../../hooks/useGetMySubmissionsAPI"; // Adjust the import path if needed
import { useSelector } from "react-redux";
import {Button} from "react-bootstrap";
const UserSubmissionLists = () => {
  const { auth } = useSelector((state) => state.auth);
  const [submissions, setSubmissions] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams(); // Replace with dynamic quest ID if needed
  const { isLoading, data } = useMySubmissionsAPI();
  const [fullData, setFullData] = useState([]);
  const [sortedBy, setSortedBy] = useState("");
  const [sortStatus, setSortStatus] = useState("");
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search");
  useEffect(() => {
    if (!isLoading) {
      setFullData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (searchValue) {
      const result = searchTable(searchValue, data);
      setFullData(result);
    } else {
      setFullData(data);
    }
  }, [searchValue, data]);

  const handleSortTable = (column) => {
    if (sortedBy !== column || sortStatus === "") {
      setSortStatus("asc");
      setSortedBy(column);
      setFullData(sortTable(fullData, "asc", column));
    } else if (sortStatus === "asc") {
      setSortStatus("desc");
      setFullData(sortTable(fullData, "desc", column));
    } else {
      setSortStatus("");
      setFullData(data);
    }
  };

  return (
    <>
       <div className="d-flex align-items-center mb-4 flex-wrap justify-content-end">
       <Button className="me-2" variant="outline-primary btn-rounded" onClick={()=>navigate("/quest-list")}>
        <i className="fa-solid fa-chevron-left"></i> <span style={{margin:"0 10px"}}>Back</span>
       </Button>
      </div>
      <div className="row">
        <div className="col-xl-13">
          <div className="table-responsive">
            <table className="submission-table table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th>Quest Title</th>
                  <th>Status</th>
                  <th>File URL</th>
                  <th>Video URL</th>
                  <th
                    className="sortBy"
                    onClick={() => handleSortTable("submittedOn")}
                  >
                    Submission Date
                    {sortedBy === "submittedOn" && (
                      <Arrow direction={sortStatus} />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {submissions.map((submission, index) => (
                  <tr key={index}>
                    <td>{submission.quest.title}</td>
                    <td>
                      <span
                        className={`badge badge-lg light badge-${
                          submission.status === "pending"
                            ? "warning"
                            : "success"
                        }`}
                      >
                        {submission.status}
                      </span>
                    </td>
                    <td><a href={submission.fileUrl} target="_blank" rel="noreferrer">{submission.fileUrl}</a></td>
                    <td><a href={submission.videoUrl} target="_blank" rel="noreferrer">{submission.videoUrl}</a></td>
                    <td>
                      {new Date(submission.submittedOn).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Start Pagination */}
        <PaginationFiltration
          data={fullData}
          setQuests={setSubmissions}
          sortStatus={sortStatus}
        />
      </div>
    </>
  );
};

export default UserSubmissionLists;
