/* tslint:disable */
/* eslint-disable */
/**
 * Code Quests APIs
 * API docs using swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'strategy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AuthResult
 */
export interface AuthResult {
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    'accessToken'?: string;
    /**
     * 
     * @type {AuthResultAuthentication}
     * @memberof AuthResult
     */
    'authentication'?: AuthResultAuthentication;
    /**
     * 
     * @type {object}
     * @memberof AuthResult
     */
    'payload'?: object;
    /**
     * 
     * @type {User}
     * @memberof AuthResult
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface AuthResultAuthentication
 */
export interface AuthResultAuthentication {
    /**
     * 
     * @type {string}
     * @memberof AuthResultAuthentication
     */
    'strategy'?: string;
}
/**
 * @type BillingInfoPostRequest
 * @export
 */
export type BillingInfoPostRequest = BillingInfo | BillingInfoList;

/**
 * @type CategoriesPostRequest
 * @export
 */
export type CategoriesPostRequest = Array<Category> | Category;

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'coverImg'?: string;
}
/**
 * 
 * @export
 * @interface CategoryData
 */
export interface CategoryData {
    /**
     * 
     * @type {string}
     * @memberof CategoryData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryData
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryData
     */
    'coverImg'?: string;
}
/**
 * 
 * @export
 * @interface CategoryPagination
 */
export interface CategoryPagination {
    /**
     * 
     * @type {number}
     * @memberof CategoryPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<Category>}
     * @memberof CategoryPagination
     */
    'data'?: Array<Category>;
}
/**
 * 
 * @export
 * @interface CategoryPatch
 */
export interface CategoryPatch {
    /**
     * 
     * @type {number}
     * @memberof CategoryPatch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryPatch
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPatch
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPatch
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPatch
     */
    'coverImg'?: string;
}
/**
 * 
 * @export
 * @interface CategoryQuery
 */
export interface CategoryQuery {
    /**
     * 
     * @type {CategoryQuerySort}
     * @memberof CategoryQuery
     */
    '$sort'?: CategoryQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoryQuery
     */
    '$select'?: Array<CategoryQuerySelectEnum>;
    /**
     * 
     * @type {Array<CategoryQueryAndInner>}
     * @memberof CategoryQuery
     */
    '$and'?: Array<CategoryQueryAndInner>;
    /**
     * 
     * @type {Array<CategoryQueryAndInnerAnyOf>}
     * @memberof CategoryQuery
     */
    '$or'?: Array<CategoryQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof CategoryQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQuery
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQuery
     */
    'description'?: QuestQueryAndInnerAnyOfTitle;
}

export const CategoryQuerySelectEnum = {
    Id: 'id',
    Name: 'name',
    Description: 'description'
} as const;

export type CategoryQuerySelectEnum = typeof CategoryQuerySelectEnum[keyof typeof CategoryQuerySelectEnum];

/**
 * 
 * @export
 * @interface CategoryQueryAndInner
 */
export interface CategoryQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof CategoryQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQueryAndInner
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQueryAndInner
     */
    'description'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {Array<CategoryQueryAndInnerAnyOf>}
     * @memberof CategoryQueryAndInner
     */
    '$or': Array<CategoryQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface CategoryQueryAndInnerAnyOf
 */
export interface CategoryQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof CategoryQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQueryAndInnerAnyOf
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof CategoryQueryAndInnerAnyOf
     */
    'description'?: QuestQueryAndInnerAnyOfTitle;
}
/**
 * 
 * @export
 * @interface CategoryQueryAndInnerAnyOf1
 */
export interface CategoryQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<CategoryQueryAndInnerAnyOf>}
     * @memberof CategoryQueryAndInnerAnyOf1
     */
    '$or': Array<CategoryQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface CategoryQuerySort
 */
export interface CategoryQuerySort {
    /**
     * 
     * @type {number}
     * @memberof CategoryQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryQuerySort
     */
    'name'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryQuerySort
     */
    'description'?: number;
}
/**
 * @type CommentsPostRequest
 * @export
 */
export type CommentsPostRequest = Comments | CommentsList;

/**
 * @type FeedbackPostRequest
 * @export
 */
export type FeedbackPostRequest = Feedback | FeedbackList;

/**
 * @type NotificationsPostRequest
 * @export
 */
export type NotificationsPostRequest = Notifications | NotificationsList;

/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    'website'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Org
     */
    'isPublic': boolean;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    'managerId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Org
     */
    'manager'?: User;
}
/**
 * 
 * @export
 * @interface OrgData
 */
export interface OrgData {
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    'website'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgData
     */
    'isPublic': boolean;
}
/**
 * 
 * @export
 * @interface OrgPagination
 */
export interface OrgPagination {
    /**
     * 
     * @type {number}
     * @memberof OrgPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<Org>}
     * @memberof OrgPagination
     */
    'data'?: Array<Org>;
}
/**
 * 
 * @export
 * @interface OrgPatch
 */
export interface OrgPatch {
    /**
     * 
     * @type {number}
     * @memberof OrgPatch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrgPatch
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgPatch
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgPatch
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgPatch
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgPatch
     */
    'website'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgPatch
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrgPatch
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrgPatch
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrgPatch
     */
    'managerId'?: number;
    /**
     * 
     * @type {User}
     * @memberof OrgPatch
     */
    'manager'?: User;
}
/**
 * 
 * @export
 * @interface OrgQuery
 */
export interface OrgQuery {
    /**
     * 
     * @type {OrgQuerySort}
     * @memberof OrgQuery
     */
    '$sort'?: OrgQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgQuery
     */
    '$select'?: Array<OrgQuerySelectEnum>;
    /**
     * 
     * @type {Array<OrgQueryAndInner>}
     * @memberof OrgQuery
     */
    '$and'?: Array<OrgQueryAndInner>;
    /**
     * 
     * @type {Array<OrgQueryAndInnerAnyOf>}
     * @memberof OrgQuery
     */
    '$or'?: Array<OrgQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof OrgQuery
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQuery
     */
    'isVerified'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQuery
     */
    'isActive'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQuery
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQuery
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
}

export const OrgQuerySelectEnum = {
    Id: 'id',
    Name: 'name',
    IsVerified: 'isVerified',
    IsActive: 'isActive',
    IsPublic: 'isPublic',
    ManagerId: 'managerId'
} as const;

export type OrgQuerySelectEnum = typeof OrgQuerySelectEnum[keyof typeof OrgQuerySelectEnum];

/**
 * 
 * @export
 * @interface OrgQueryAndInner
 */
export interface OrgQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof OrgQueryAndInner
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInner
     */
    'isVerified'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInner
     */
    'isActive'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInner
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQueryAndInner
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {Array<OrgQueryAndInnerAnyOf>}
     * @memberof OrgQueryAndInner
     */
    '$or': Array<OrgQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface OrgQueryAndInnerAnyOf
 */
export interface OrgQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'isVerified'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'isActive'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof OrgQueryAndInnerAnyOf
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
}
/**
 * 
 * @export
 * @interface OrgQueryAndInnerAnyOf1
 */
export interface OrgQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<OrgQueryAndInnerAnyOf>}
     * @memberof OrgQueryAndInnerAnyOf1
     */
    '$or': Array<OrgQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface OrgQuerySort
 */
export interface OrgQuerySort {
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'name'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'isVerified'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'isActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'isPublic'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgQuerySort
     */
    'managerId'?: number;
}
/**
 * @type OrgsPostRequest
 * @export
 */
export type OrgsPostRequest = Array<Org> | Org;

/**
 * @type PrizesPostRequest
 * @export
 */
export type PrizesPostRequest = Prizes | PrizesList;

/**
 * 
 * @export
 * @interface Quest
 */
export interface Quest {
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'status': QuestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'type': QuestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'phase': QuestPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'heroImg'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'publishedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'registrationDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'submissionDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'reviewDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'endedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'updatedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'archivedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'minParticipants'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quest
     */
    'updates'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Quest
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Quest
     */
    'requiresApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quest
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quest
     */
    'requirements'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quest
     */
    'acceptanceCriteria'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quest
     */
    'technologies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Quest
     */
    'evaluationCriteriaLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'categoryId': number;
    /**
     * 
     * @type {Category}
     * @memberof Quest
     */
    'category'?: Category;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'orgId': number;
    /**
     * 
     * @type {Org}
     * @memberof Quest
     */
    'org'?: Org;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'managerId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Quest
     */
    'manager'?: User;
    /**
     * 
     * @type {Array<Prize>}
     * @memberof Quest
     */
    'prizes'?: Array<Prize>;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'registrationsCount': number;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'submissionsCount': number;
    /**
     * 
     * @type {number}
     * @memberof Quest
     */
    'scorecardId'?: number;
    /**
     * 
     * @type {Scorecard}
     * @memberof Quest
     */
    'scorecard'?: Scorecard;
}

export const QuestStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestStatusEnum = typeof QuestStatusEnum[keyof typeof QuestStatusEnum];
export const QuestTypeEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestTypeEnum = typeof QuestTypeEnum[keyof typeof QuestTypeEnum];
export const QuestPhaseEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestPhaseEnum = typeof QuestPhaseEnum[keyof typeof QuestPhaseEnum];

/**
 * 
 * @export
 * @interface QuestData
 */
export interface QuestData {
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'status': QuestDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'type': QuestDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'phase': QuestDataPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'heroImg'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'registrationDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'submissionDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'reviewDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestData
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestData
     */
    'minParticipants'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestData
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestData
     */
    'requiresApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestData
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestData
     */
    'requirements'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestData
     */
    'acceptanceCriteria'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestData
     */
    'technologies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestData
     */
    'evaluationCriteriaLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestData
     */
    'categoryId': number;
    /**
     * 
     * @type {number}
     * @memberof QuestData
     */
    'orgId': number;
    /**
     * 
     * @type {number}
     * @memberof QuestData
     */
    'scorecardId'?: number;
}

export const QuestDataStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestDataStatusEnum = typeof QuestDataStatusEnum[keyof typeof QuestDataStatusEnum];
export const QuestDataTypeEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestDataTypeEnum = typeof QuestDataTypeEnum[keyof typeof QuestDataTypeEnum];
export const QuestDataPhaseEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestDataPhaseEnum = typeof QuestDataPhaseEnum[keyof typeof QuestDataPhaseEnum];

/**
 * 
 * @export
 * @interface QuestPagination
 */
export interface QuestPagination {
    /**
     * 
     * @type {number}
     * @memberof QuestPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<Quest>}
     * @memberof QuestPagination
     */
    'data'?: Array<Quest>;
}
/**
 * 
 * @export
 * @interface QuestPatch
 */
export interface QuestPatch {
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'status'?: QuestPatchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'type'?: QuestPatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'phase'?: QuestPatchPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'heroImg'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'publishedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'registrationDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'submissionDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'reviewDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'endedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'updatedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'archivedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'minParticipants'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestPatch
     */
    'updates'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QuestPatch
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestPatch
     */
    'requiresApproval'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestPatch
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestPatch
     */
    'requirements'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestPatch
     */
    'acceptanceCriteria'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestPatch
     */
    'technologies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestPatch
     */
    'evaluationCriteriaLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'categoryId'?: number;
    /**
     * 
     * @type {Category}
     * @memberof QuestPatch
     */
    'category'?: Category;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'orgId'?: number;
    /**
     * 
     * @type {Org}
     * @memberof QuestPatch
     */
    'org'?: Org;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'managerId'?: number;
    /**
     * 
     * @type {User}
     * @memberof QuestPatch
     */
    'manager'?: User;
    /**
     * 
     * @type {Array<Prize>}
     * @memberof QuestPatch
     */
    'prizes'?: Array<Prize>;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'registrationsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'submissionsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestPatch
     */
    'scorecardId'?: number;
    /**
     * 
     * @type {Scorecard}
     * @memberof QuestPatch
     */
    'scorecard'?: Scorecard;
}

export const QuestPatchStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestPatchStatusEnum = typeof QuestPatchStatusEnum[keyof typeof QuestPatchStatusEnum];
export const QuestPatchTypeEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestPatchTypeEnum = typeof QuestPatchTypeEnum[keyof typeof QuestPatchTypeEnum];
export const QuestPatchPhaseEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestPatchPhaseEnum = typeof QuestPatchPhaseEnum[keyof typeof QuestPatchPhaseEnum];

/**
 * 
 * @export
 * @interface QuestQuery
 */
export interface QuestQuery {
    /**
     * 
     * @type {QuestQuerySort}
     * @memberof QuestQuery
     */
    '$sort'?: QuestQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQuery
     */
    '$select'?: Array<QuestQuerySelectEnum>;
    /**
     * 
     * @type {Array<QuestQueryAndInner>}
     * @memberof QuestQuery
     */
    '$and'?: Array<QuestQueryAndInner>;
    /**
     * 
     * @type {Array<QuestQueryAndInnerAnyOf>}
     * @memberof QuestQuery
     */
    '$or'?: Array<QuestQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof QuestQuery
     */
    'title'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfStatus}
     * @memberof QuestQuery
     */
    'status'?: QuestQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfType}
     * @memberof QuestQuery
     */
    'type'?: QuestQueryAndInnerAnyOfType;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfPhase}
     * @memberof QuestQuery
     */
    'phase'?: QuestQueryAndInnerAnyOfPhase;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof QuestQuery
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQuery
     */
    'categoryId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQuery
     */
    'orgId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQuery
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
}

export const QuestQuerySelectEnum = {
    Id: 'id',
    Title: 'title',
    Status: 'status',
    Type: 'type',
    Phase: 'phase',
    IsPublic: 'isPublic',
    CategoryId: 'categoryId',
    OrgId: 'orgId',
    ManagerId: 'managerId'
} as const;

export type QuestQuerySelectEnum = typeof QuestQuerySelectEnum[keyof typeof QuestQuerySelectEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInner
 */
export interface QuestQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof QuestQueryAndInner
     */
    'title'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfStatus}
     * @memberof QuestQueryAndInner
     */
    'status'?: QuestQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfType}
     * @memberof QuestQueryAndInner
     */
    'type'?: QuestQueryAndInnerAnyOfType;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfPhase}
     * @memberof QuestQueryAndInner
     */
    'phase'?: QuestQueryAndInnerAnyOfPhase;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof QuestQueryAndInner
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInner
     */
    'categoryId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInner
     */
    'orgId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInner
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {Array<QuestQueryAndInnerAnyOf>}
     * @memberof QuestQueryAndInner
     */
    '$or': Array<QuestQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOf
 */
export interface QuestQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'title'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfStatus}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'status'?: QuestQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfType}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'type'?: QuestQueryAndInnerAnyOfType;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfPhase}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'phase'?: QuestQueryAndInnerAnyOfPhase;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'isPublic'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'categoryId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'orgId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof QuestQueryAndInnerAnyOf
     */
    'managerId'?: SubmissionQueryAndInnerAnyOfId;
}
/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOf1
 */
export interface QuestQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<QuestQueryAndInnerAnyOf>}
     * @memberof QuestQueryAndInnerAnyOf1
     */
    '$or': Array<QuestQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfPhase
 */
export interface QuestQueryAndInnerAnyOfPhase {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$gt'?: QuestQueryAndInnerAnyOfPhaseGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$gte'?: QuestQueryAndInnerAnyOfPhaseGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$lt'?: QuestQueryAndInnerAnyOfPhaseLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$lte'?: QuestQueryAndInnerAnyOfPhaseLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$ne'?: QuestQueryAndInnerAnyOfPhaseNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfPhaseInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfPhase
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfPhaseNinEnum>;
}

export const QuestQueryAndInnerAnyOfPhaseGtEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseGtEnum = typeof QuestQueryAndInnerAnyOfPhaseGtEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseGtEnum];
export const QuestQueryAndInnerAnyOfPhaseGteEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseGteEnum = typeof QuestQueryAndInnerAnyOfPhaseGteEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseGteEnum];
export const QuestQueryAndInnerAnyOfPhaseLtEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseLtEnum = typeof QuestQueryAndInnerAnyOfPhaseLtEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseLtEnum];
export const QuestQueryAndInnerAnyOfPhaseLteEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseLteEnum = typeof QuestQueryAndInnerAnyOfPhaseLteEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseLteEnum];
export const QuestQueryAndInnerAnyOfPhaseNeEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseNeEnum = typeof QuestQueryAndInnerAnyOfPhaseNeEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseNeEnum];
export const QuestQueryAndInnerAnyOfPhaseInEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseInEnum = typeof QuestQueryAndInnerAnyOfPhaseInEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseInEnum];
export const QuestQueryAndInnerAnyOfPhaseNinEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseNinEnum = typeof QuestQueryAndInnerAnyOfPhaseNinEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseNinEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfPhaseAnyOf
 */
export interface QuestQueryAndInnerAnyOfPhaseAnyOf {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$gt'?: QuestQueryAndInnerAnyOfPhaseAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$gte'?: QuestQueryAndInnerAnyOfPhaseAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$lt'?: QuestQueryAndInnerAnyOfPhaseAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$lte'?: QuestQueryAndInnerAnyOfPhaseAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$ne'?: QuestQueryAndInnerAnyOfPhaseAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfPhaseAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfPhaseAnyOf
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfPhaseAnyOfNinEnum>;
}

export const QuestQueryAndInnerAnyOfPhaseAnyOfGtEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfGtEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfGtEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfGtEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfGteEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfGteEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfGteEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfGteEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfLtEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfLtEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfLtEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfLtEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfLteEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfLteEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfLteEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfLteEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfNeEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfNeEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfNeEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfNeEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfInEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfInEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfInEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfInEnum];
export const QuestQueryAndInnerAnyOfPhaseAnyOfNinEnum = {
    Registration: 'registration',
    Submission: 'submission',
    Review: 'review',
    Ended: 'ended'
} as const;

export type QuestQueryAndInnerAnyOfPhaseAnyOfNinEnum = typeof QuestQueryAndInnerAnyOfPhaseAnyOfNinEnum[keyof typeof QuestQueryAndInnerAnyOfPhaseAnyOfNinEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfStatus
 */
export interface QuestQueryAndInnerAnyOfStatus {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$gt'?: QuestQueryAndInnerAnyOfStatusGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$gte'?: QuestQueryAndInnerAnyOfStatusGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$lt'?: QuestQueryAndInnerAnyOfStatusLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$lte'?: QuestQueryAndInnerAnyOfStatusLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$ne'?: QuestQueryAndInnerAnyOfStatusNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfStatusInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfStatus
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfStatusNinEnum>;
}

export const QuestQueryAndInnerAnyOfStatusGtEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusGtEnum = typeof QuestQueryAndInnerAnyOfStatusGtEnum[keyof typeof QuestQueryAndInnerAnyOfStatusGtEnum];
export const QuestQueryAndInnerAnyOfStatusGteEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusGteEnum = typeof QuestQueryAndInnerAnyOfStatusGteEnum[keyof typeof QuestQueryAndInnerAnyOfStatusGteEnum];
export const QuestQueryAndInnerAnyOfStatusLtEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusLtEnum = typeof QuestQueryAndInnerAnyOfStatusLtEnum[keyof typeof QuestQueryAndInnerAnyOfStatusLtEnum];
export const QuestQueryAndInnerAnyOfStatusLteEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusLteEnum = typeof QuestQueryAndInnerAnyOfStatusLteEnum[keyof typeof QuestQueryAndInnerAnyOfStatusLteEnum];
export const QuestQueryAndInnerAnyOfStatusNeEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusNeEnum = typeof QuestQueryAndInnerAnyOfStatusNeEnum[keyof typeof QuestQueryAndInnerAnyOfStatusNeEnum];
export const QuestQueryAndInnerAnyOfStatusInEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusInEnum = typeof QuestQueryAndInnerAnyOfStatusInEnum[keyof typeof QuestQueryAndInnerAnyOfStatusInEnum];
export const QuestQueryAndInnerAnyOfStatusNinEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusNinEnum = typeof QuestQueryAndInnerAnyOfStatusNinEnum[keyof typeof QuestQueryAndInnerAnyOfStatusNinEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfStatusAnyOf
 */
export interface QuestQueryAndInnerAnyOfStatusAnyOf {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$gt'?: QuestQueryAndInnerAnyOfStatusAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$gte'?: QuestQueryAndInnerAnyOfStatusAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$lt'?: QuestQueryAndInnerAnyOfStatusAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$lte'?: QuestQueryAndInnerAnyOfStatusAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$ne'?: QuestQueryAndInnerAnyOfStatusAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfStatusAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfStatusAnyOf
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfStatusAnyOfNinEnum>;
}

export const QuestQueryAndInnerAnyOfStatusAnyOfGtEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfGtEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfGtEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfGtEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfGteEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfGteEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfGteEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfGteEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfLtEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfLtEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfLtEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfLtEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfLteEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfLteEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfLteEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfLteEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfNeEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfNeEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfNeEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfNeEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfInEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfInEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfInEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfInEnum];
export const QuestQueryAndInnerAnyOfStatusAnyOfNinEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived'
} as const;

export type QuestQueryAndInnerAnyOfStatusAnyOfNinEnum = typeof QuestQueryAndInnerAnyOfStatusAnyOfNinEnum[keyof typeof QuestQueryAndInnerAnyOfStatusAnyOfNinEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfTitle
 */
export interface QuestQueryAndInnerAnyOfTitle {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTitle
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfTitleAnyOf
 */
export interface QuestQueryAndInnerAnyOfTitleAnyOf {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTitleAnyOf
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfType
 */
export interface QuestQueryAndInnerAnyOfType {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$gt'?: QuestQueryAndInnerAnyOfTypeGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$gte'?: QuestQueryAndInnerAnyOfTypeGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$lt'?: QuestQueryAndInnerAnyOfTypeLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$lte'?: QuestQueryAndInnerAnyOfTypeLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$ne'?: QuestQueryAndInnerAnyOfTypeNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfTypeInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfType
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfTypeNinEnum>;
}

export const QuestQueryAndInnerAnyOfTypeGtEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeGtEnum = typeof QuestQueryAndInnerAnyOfTypeGtEnum[keyof typeof QuestQueryAndInnerAnyOfTypeGtEnum];
export const QuestQueryAndInnerAnyOfTypeGteEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeGteEnum = typeof QuestQueryAndInnerAnyOfTypeGteEnum[keyof typeof QuestQueryAndInnerAnyOfTypeGteEnum];
export const QuestQueryAndInnerAnyOfTypeLtEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeLtEnum = typeof QuestQueryAndInnerAnyOfTypeLtEnum[keyof typeof QuestQueryAndInnerAnyOfTypeLtEnum];
export const QuestQueryAndInnerAnyOfTypeLteEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeLteEnum = typeof QuestQueryAndInnerAnyOfTypeLteEnum[keyof typeof QuestQueryAndInnerAnyOfTypeLteEnum];
export const QuestQueryAndInnerAnyOfTypeNeEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeNeEnum = typeof QuestQueryAndInnerAnyOfTypeNeEnum[keyof typeof QuestQueryAndInnerAnyOfTypeNeEnum];
export const QuestQueryAndInnerAnyOfTypeInEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeInEnum = typeof QuestQueryAndInnerAnyOfTypeInEnum[keyof typeof QuestQueryAndInnerAnyOfTypeInEnum];
export const QuestQueryAndInnerAnyOfTypeNinEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeNinEnum = typeof QuestQueryAndInnerAnyOfTypeNinEnum[keyof typeof QuestQueryAndInnerAnyOfTypeNinEnum];

/**
 * 
 * @export
 * @interface QuestQueryAndInnerAnyOfTypeAnyOf
 */
export interface QuestQueryAndInnerAnyOfTypeAnyOf {
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$gt'?: QuestQueryAndInnerAnyOfTypeAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$gte'?: QuestQueryAndInnerAnyOfTypeAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$lt'?: QuestQueryAndInnerAnyOfTypeAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$lte'?: QuestQueryAndInnerAnyOfTypeAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$ne'?: QuestQueryAndInnerAnyOfTypeAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$in'?: Array<QuestQueryAndInnerAnyOfTypeAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestQueryAndInnerAnyOfTypeAnyOf
     */
    '$nin'?: Array<QuestQueryAndInnerAnyOfTypeAnyOfNinEnum>;
}

export const QuestQueryAndInnerAnyOfTypeAnyOfGtEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfGtEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfGtEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfGtEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfGteEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfGteEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfGteEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfGteEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfLtEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfLtEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfLtEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfLtEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfLteEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfLteEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfLteEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfLteEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfNeEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfNeEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfNeEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfNeEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfInEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfInEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfInEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfInEnum];
export const QuestQueryAndInnerAnyOfTypeAnyOfNinEnum = {
    Contest: 'contest',
    Race: 'race'
} as const;

export type QuestQueryAndInnerAnyOfTypeAnyOfNinEnum = typeof QuestQueryAndInnerAnyOfTypeAnyOfNinEnum[keyof typeof QuestQueryAndInnerAnyOfTypeAnyOfNinEnum];

/**
 * 
 * @export
 * @interface QuestQuerySort
 */
export interface QuestQuerySort {
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'title'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'phase'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'isPublic'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'categoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'orgId'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestQuerySort
     */
    'managerId'?: number;
}
/**
 * @type QuestsPostRequest
 * @export
 */
export type QuestsPostRequest = Array<Quest> | Quest;

/**
 * @type QuestsQuestIdEnlistPostRequest
 * @export
 */
export type QuestsQuestIdEnlistPostRequest = Array<Registration> | Registration;

/**
 * @type QuestsQuestIdReviewsPostRequest
 * @export
 */
export type QuestsQuestIdReviewsPostRequest = QuestId | QuestIdList;

/**
 * @type QuestsQuestIdSubmissionsPostRequest
 * @export
 */
export type QuestsQuestIdSubmissionsPostRequest = Array<Submission> | Submission;

/**
 * 
 * @export
 * @interface Registration
 */
export interface Registration {
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    'status'?: RegistrationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    'role'?: RegistrationRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Registration
     */
    'isInvited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Registration
     */
    'isApproved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    'joinedOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Registration
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    'questId'?: number;
    /**
     * 
     * @type {Quest}
     * @memberof Registration
     */
    'quest'?: Quest;
}

export const RegistrationStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationStatusEnum = typeof RegistrationStatusEnum[keyof typeof RegistrationStatusEnum];
export const RegistrationRoleEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationRoleEnum = typeof RegistrationRoleEnum[keyof typeof RegistrationRoleEnum];

/**
 * 
 * @export
 * @interface RegistrationData
 */
export interface RegistrationData {
    /**
     * 
     * @type {string}
     * @memberof RegistrationData
     */
    'status'?: RegistrationDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationData
     */
    'role'?: RegistrationDataRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationData
     */
    'isApproved'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RegistrationData
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationData
     */
    'questId'?: number;
}

export const RegistrationDataStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationDataStatusEnum = typeof RegistrationDataStatusEnum[keyof typeof RegistrationDataStatusEnum];
export const RegistrationDataRoleEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationDataRoleEnum = typeof RegistrationDataRoleEnum[keyof typeof RegistrationDataRoleEnum];

/**
 * 
 * @export
 * @interface RegistrationPagination
 */
export interface RegistrationPagination {
    /**
     * 
     * @type {number}
     * @memberof RegistrationPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<Registration>}
     * @memberof RegistrationPagination
     */
    'data'?: Array<Registration>;
}
/**
 * 
 * @export
 * @interface RegistrationPatch
 */
export interface RegistrationPatch {
    /**
     * 
     * @type {number}
     * @memberof RegistrationPatch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegistrationPatch
     */
    'status'?: RegistrationPatchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationPatch
     */
    'role'?: RegistrationPatchRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationPatch
     */
    'isInvited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationPatch
     */
    'isApproved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegistrationPatch
     */
    'joinedOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegistrationPatch
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof RegistrationPatch
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof RegistrationPatch
     */
    'questId'?: number;
    /**
     * 
     * @type {Quest}
     * @memberof RegistrationPatch
     */
    'quest'?: Quest;
}

export const RegistrationPatchStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationPatchStatusEnum = typeof RegistrationPatchStatusEnum[keyof typeof RegistrationPatchStatusEnum];
export const RegistrationPatchRoleEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationPatchRoleEnum = typeof RegistrationPatchRoleEnum[keyof typeof RegistrationPatchRoleEnum];

/**
 * 
 * @export
 * @interface RegistrationQuery
 */
export interface RegistrationQuery {
    /**
     * 
     * @type {RegistrationQuerySort}
     * @memberof RegistrationQuery
     */
    '$sort'?: RegistrationQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQuery
     */
    '$select'?: Array<RegistrationQuerySelectEnum>;
    /**
     * 
     * @type {Array<RegistrationQueryAndInner>}
     * @memberof RegistrationQuery
     */
    '$and'?: Array<RegistrationQueryAndInner>;
    /**
     * 
     * @type {Array<RegistrationQueryAndInnerAnyOf>}
     * @memberof RegistrationQuery
     */
    '$or'?: Array<RegistrationQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfStatus}
     * @memberof RegistrationQuery
     */
    'status'?: RegistrationQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfRole}
     * @memberof RegistrationQuery
     */
    'role'?: RegistrationQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQuery
     */
    'isInvited'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQuery
     */
    'isApproved'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof RegistrationQuery
     */
    'joinedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQuery
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQuery
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
}

export const RegistrationQuerySelectEnum = {
    Id: 'id',
    Status: 'status',
    Role: 'role',
    IsInvited: 'isInvited',
    IsApproved: 'isApproved',
    JoinedOn: 'joinedOn',
    UserId: 'userId',
    QuestId: 'questId'
} as const;

export type RegistrationQuerySelectEnum = typeof RegistrationQuerySelectEnum[keyof typeof RegistrationQuerySelectEnum];

/**
 * 
 * @export
 * @interface RegistrationQueryAndInner
 */
export interface RegistrationQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfStatus}
     * @memberof RegistrationQueryAndInner
     */
    'status'?: RegistrationQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfRole}
     * @memberof RegistrationQueryAndInner
     */
    'role'?: RegistrationQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQueryAndInner
     */
    'isInvited'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQueryAndInner
     */
    'isApproved'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof RegistrationQueryAndInner
     */
    'joinedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInner
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInner
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {Array<RegistrationQueryAndInnerAnyOf>}
     * @memberof RegistrationQueryAndInner
     */
    '$or': Array<RegistrationQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOf
 */
export interface RegistrationQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfStatus}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'status'?: RegistrationQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfRole}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'role'?: RegistrationQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'isInvited'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {RegistrationQueryAndInnerAnyOfIsInvited}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'isApproved'?: RegistrationQueryAndInnerAnyOfIsInvited;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'joinedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof RegistrationQueryAndInnerAnyOf
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
}
/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOf1
 */
export interface RegistrationQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<RegistrationQueryAndInnerAnyOf>}
     * @memberof RegistrationQueryAndInnerAnyOf1
     */
    '$or': Array<RegistrationQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfIsInvited
 */
export interface RegistrationQueryAndInnerAnyOfIsInvited {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$gt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$gte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$lt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$lte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$ne'?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$in'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvited
     */
    '$nin'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
 */
export interface RegistrationQueryAndInnerAnyOfIsInvitedAnyOf {
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$gt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$gte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$lt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$lte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$ne'?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$in'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof RegistrationQueryAndInnerAnyOfIsInvitedAnyOf
     */
    '$nin'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfRole
 */
export interface RegistrationQueryAndInnerAnyOfRole {
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$gt'?: RegistrationQueryAndInnerAnyOfRoleGtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$gte'?: RegistrationQueryAndInnerAnyOfRoleGteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$lt'?: RegistrationQueryAndInnerAnyOfRoleLtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$lte'?: RegistrationQueryAndInnerAnyOfRoleLteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$ne'?: RegistrationQueryAndInnerAnyOfRoleNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$in'?: Array<RegistrationQueryAndInnerAnyOfRoleInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfRole
     */
    '$nin'?: Array<RegistrationQueryAndInnerAnyOfRoleNinEnum>;
}

export const RegistrationQueryAndInnerAnyOfRoleGtEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleGtEnum = typeof RegistrationQueryAndInnerAnyOfRoleGtEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleGtEnum];
export const RegistrationQueryAndInnerAnyOfRoleGteEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleGteEnum = typeof RegistrationQueryAndInnerAnyOfRoleGteEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleGteEnum];
export const RegistrationQueryAndInnerAnyOfRoleLtEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleLtEnum = typeof RegistrationQueryAndInnerAnyOfRoleLtEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleLtEnum];
export const RegistrationQueryAndInnerAnyOfRoleLteEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleLteEnum = typeof RegistrationQueryAndInnerAnyOfRoleLteEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleLteEnum];
export const RegistrationQueryAndInnerAnyOfRoleNeEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleNeEnum = typeof RegistrationQueryAndInnerAnyOfRoleNeEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleNeEnum];
export const RegistrationQueryAndInnerAnyOfRoleInEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleInEnum = typeof RegistrationQueryAndInnerAnyOfRoleInEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleInEnum];
export const RegistrationQueryAndInnerAnyOfRoleNinEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleNinEnum = typeof RegistrationQueryAndInnerAnyOfRoleNinEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleNinEnum];

/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfRoleAnyOf
 */
export interface RegistrationQueryAndInnerAnyOfRoleAnyOf {
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$gt'?: RegistrationQueryAndInnerAnyOfRoleAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$gte'?: RegistrationQueryAndInnerAnyOfRoleAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$lt'?: RegistrationQueryAndInnerAnyOfRoleAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$lte'?: RegistrationQueryAndInnerAnyOfRoleAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$ne'?: RegistrationQueryAndInnerAnyOfRoleAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$in'?: Array<RegistrationQueryAndInnerAnyOfRoleAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfRoleAnyOf
     */
    '$nin'?: Array<RegistrationQueryAndInnerAnyOfRoleAnyOfNinEnum>;
}

export const RegistrationQueryAndInnerAnyOfRoleAnyOfGtEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfGtEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfGtEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfGtEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfGteEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfGteEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfGteEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfGteEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfLtEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfLtEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfLtEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfLtEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfLteEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfLteEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfLteEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfLteEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfNeEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfNeEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfNeEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfNeEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfInEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfInEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfInEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfInEnum];
export const RegistrationQueryAndInnerAnyOfRoleAnyOfNinEnum = {
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type RegistrationQueryAndInnerAnyOfRoleAnyOfNinEnum = typeof RegistrationQueryAndInnerAnyOfRoleAnyOfNinEnum[keyof typeof RegistrationQueryAndInnerAnyOfRoleAnyOfNinEnum];

/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfStatus
 */
export interface RegistrationQueryAndInnerAnyOfStatus {
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$gt'?: RegistrationQueryAndInnerAnyOfStatusGtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$gte'?: RegistrationQueryAndInnerAnyOfStatusGteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$lt'?: RegistrationQueryAndInnerAnyOfStatusLtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$lte'?: RegistrationQueryAndInnerAnyOfStatusLteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$ne'?: RegistrationQueryAndInnerAnyOfStatusNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$in'?: Array<RegistrationQueryAndInnerAnyOfStatusInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfStatus
     */
    '$nin'?: Array<RegistrationQueryAndInnerAnyOfStatusNinEnum>;
}

export const RegistrationQueryAndInnerAnyOfStatusGtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusGtEnum = typeof RegistrationQueryAndInnerAnyOfStatusGtEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusGtEnum];
export const RegistrationQueryAndInnerAnyOfStatusGteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusGteEnum = typeof RegistrationQueryAndInnerAnyOfStatusGteEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusGteEnum];
export const RegistrationQueryAndInnerAnyOfStatusLtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusLtEnum = typeof RegistrationQueryAndInnerAnyOfStatusLtEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusLtEnum];
export const RegistrationQueryAndInnerAnyOfStatusLteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusLteEnum = typeof RegistrationQueryAndInnerAnyOfStatusLteEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusLteEnum];
export const RegistrationQueryAndInnerAnyOfStatusNeEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusNeEnum = typeof RegistrationQueryAndInnerAnyOfStatusNeEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusNeEnum];
export const RegistrationQueryAndInnerAnyOfStatusInEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusInEnum = typeof RegistrationQueryAndInnerAnyOfStatusInEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusInEnum];
export const RegistrationQueryAndInnerAnyOfStatusNinEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusNinEnum = typeof RegistrationQueryAndInnerAnyOfStatusNinEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusNinEnum];

/**
 * 
 * @export
 * @interface RegistrationQueryAndInnerAnyOfStatusAnyOf
 */
export interface RegistrationQueryAndInnerAnyOfStatusAnyOf {
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$gt'?: RegistrationQueryAndInnerAnyOfStatusAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$gte'?: RegistrationQueryAndInnerAnyOfStatusAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$lt'?: RegistrationQueryAndInnerAnyOfStatusAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$lte'?: RegistrationQueryAndInnerAnyOfStatusAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$ne'?: RegistrationQueryAndInnerAnyOfStatusAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$in'?: Array<RegistrationQueryAndInnerAnyOfStatusAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationQueryAndInnerAnyOfStatusAnyOf
     */
    '$nin'?: Array<RegistrationQueryAndInnerAnyOfStatusAnyOfNinEnum>;
}

export const RegistrationQueryAndInnerAnyOfStatusAnyOfGtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfGtEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfGtEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfGtEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfGteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfGteEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfGteEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfGteEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfLtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfLtEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfLtEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfLtEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfLteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfLteEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfLteEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfLteEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfNeEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfNeEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfNeEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfNeEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfInEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfInEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfInEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfInEnum];
export const RegistrationQueryAndInnerAnyOfStatusAnyOfNinEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Blocked: 'blocked'
} as const;

export type RegistrationQueryAndInnerAnyOfStatusAnyOfNinEnum = typeof RegistrationQueryAndInnerAnyOfStatusAnyOfNinEnum[keyof typeof RegistrationQueryAndInnerAnyOfStatusAnyOfNinEnum];

/**
 * 
 * @export
 * @interface RegistrationQuerySort
 */
export interface RegistrationQuerySort {
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'role'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'isInvited'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'isApproved'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'joinedOn'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationQuerySort
     */
    'questId'?: number;
}
/**
 * @type ScorecardsPostRequest
 * @export
 */
export type ScorecardsPostRequest = Scorecards | ScorecardsList;

/**
 * 
 * @export
 * @interface Submission
 */
export interface Submission {
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    'totalScore': number;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'status'?: SubmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'fileUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'submittedOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Submission
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    'questId'?: number;
    /**
     * 
     * @type {Quest}
     * @memberof Submission
     */
    'quest'?: Quest;
}

export const SubmissionStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionStatusEnum = typeof SubmissionStatusEnum[keyof typeof SubmissionStatusEnum];

/**
 * 
 * @export
 * @interface SubmissionData
 */
export interface SubmissionData {
    /**
     * 
     * @type {number}
     * @memberof SubmissionData
     */
    'totalScore': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionData
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionData
     */
    'fileUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionData
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionData
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionData
     */
    'questId'?: number;
}
/**
 * 
 * @export
 * @interface SubmissionPagination
 */
export interface SubmissionPagination {
    /**
     * 
     * @type {number}
     * @memberof SubmissionPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<Submission>}
     * @memberof SubmissionPagination
     */
    'data'?: Array<Submission>;
}
/**
 * 
 * @export
 * @interface SubmissionPatch
 */
export interface SubmissionPatch {
    /**
     * 
     * @type {number}
     * @memberof SubmissionPatch
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionPatch
     */
    'totalScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPatch
     */
    'status'?: SubmissionPatchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPatch
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPatch
     */
    'fileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPatch
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPatch
     */
    'submittedOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionPatch
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof SubmissionPatch
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof SubmissionPatch
     */
    'questId'?: number;
    /**
     * 
     * @type {Quest}
     * @memberof SubmissionPatch
     */
    'quest'?: Quest;
}

export const SubmissionPatchStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionPatchStatusEnum = typeof SubmissionPatchStatusEnum[keyof typeof SubmissionPatchStatusEnum];

/**
 * 
 * @export
 * @interface SubmissionQuery
 */
export interface SubmissionQuery {
    /**
     * 
     * @type {SubmissionQuerySort}
     * @memberof SubmissionQuery
     */
    '$sort'?: SubmissionQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQuery
     */
    '$select'?: Array<SubmissionQuerySelectEnum>;
    /**
     * 
     * @type {Array<SubmissionQueryAndInner>}
     * @memberof SubmissionQuery
     */
    '$and'?: Array<SubmissionQueryAndInner>;
    /**
     * 
     * @type {Array<SubmissionQueryAndInnerAnyOf>}
     * @memberof SubmissionQuery
     */
    '$or'?: Array<SubmissionQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfTotalScore}
     * @memberof SubmissionQuery
     */
    'totalScore'?: SubmissionQueryAndInnerAnyOfTotalScore;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfStatus}
     * @memberof SubmissionQuery
     */
    'status'?: SubmissionQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQuery
     */
    'fileUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQuery
     */
    'videoUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof SubmissionQuery
     */
    'submittedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQuery
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQuery
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
}

export const SubmissionQuerySelectEnum = {
    Id: 'id',
    TotalScore: 'totalScore',
    Status: 'status',
    FileUrl: 'fileUrl',
    VideoUrl: 'videoUrl',
    SubmittedOn: 'submittedOn',
    UserId: 'userId',
    QuestId: 'questId'
} as const;

export type SubmissionQuerySelectEnum = typeof SubmissionQuerySelectEnum[keyof typeof SubmissionQuerySelectEnum];

/**
 * 
 * @export
 * @interface SubmissionQueryAndInner
 */
export interface SubmissionQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfTotalScore}
     * @memberof SubmissionQueryAndInner
     */
    'totalScore'?: SubmissionQueryAndInnerAnyOfTotalScore;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfStatus}
     * @memberof SubmissionQueryAndInner
     */
    'status'?: SubmissionQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQueryAndInner
     */
    'fileUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQueryAndInner
     */
    'videoUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof SubmissionQueryAndInner
     */
    'submittedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInner
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInner
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {Array<SubmissionQueryAndInnerAnyOf>}
     * @memberof SubmissionQueryAndInner
     */
    '$or': Array<SubmissionQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOf
 */
export interface SubmissionQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfTotalScore}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'totalScore'?: SubmissionQueryAndInnerAnyOfTotalScore;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfStatus}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'status'?: SubmissionQueryAndInnerAnyOfStatus;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'fileUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'videoUrl'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfSubmittedOn}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'submittedOn'?: SubmissionQueryAndInnerAnyOfSubmittedOn;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'userId'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof SubmissionQueryAndInnerAnyOf
     */
    'questId'?: SubmissionQueryAndInnerAnyOfId;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOf1
 */
export interface SubmissionQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<SubmissionQueryAndInnerAnyOf>}
     * @memberof SubmissionQueryAndInnerAnyOf1
     */
    '$or': Array<SubmissionQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfFileUrl
 */
export interface SubmissionQueryAndInnerAnyOfFileUrl {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrl
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfFileUrlAnyOf
 */
export interface SubmissionQueryAndInnerAnyOfFileUrlAnyOf {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfFileUrlAnyOf
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfId
 */
export interface SubmissionQueryAndInnerAnyOfId {
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfId
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfIdAnyOf
 */
export interface SubmissionQueryAndInnerAnyOfIdAnyOf {
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfIdAnyOf
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfStatus
 */
export interface SubmissionQueryAndInnerAnyOfStatus {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$gt'?: SubmissionQueryAndInnerAnyOfStatusGtEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$gte'?: SubmissionQueryAndInnerAnyOfStatusGteEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$lt'?: SubmissionQueryAndInnerAnyOfStatusLtEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$lte'?: SubmissionQueryAndInnerAnyOfStatusLteEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$ne'?: SubmissionQueryAndInnerAnyOfStatusNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$in'?: Array<SubmissionQueryAndInnerAnyOfStatusInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfStatus
     */
    '$nin'?: Array<SubmissionQueryAndInnerAnyOfStatusNinEnum>;
}

export const SubmissionQueryAndInnerAnyOfStatusGtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusGtEnum = typeof SubmissionQueryAndInnerAnyOfStatusGtEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusGtEnum];
export const SubmissionQueryAndInnerAnyOfStatusGteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusGteEnum = typeof SubmissionQueryAndInnerAnyOfStatusGteEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusGteEnum];
export const SubmissionQueryAndInnerAnyOfStatusLtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusLtEnum = typeof SubmissionQueryAndInnerAnyOfStatusLtEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusLtEnum];
export const SubmissionQueryAndInnerAnyOfStatusLteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusLteEnum = typeof SubmissionQueryAndInnerAnyOfStatusLteEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusLteEnum];
export const SubmissionQueryAndInnerAnyOfStatusNeEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusNeEnum = typeof SubmissionQueryAndInnerAnyOfStatusNeEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusNeEnum];
export const SubmissionQueryAndInnerAnyOfStatusInEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusInEnum = typeof SubmissionQueryAndInnerAnyOfStatusInEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusInEnum];
export const SubmissionQueryAndInnerAnyOfStatusNinEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusNinEnum = typeof SubmissionQueryAndInnerAnyOfStatusNinEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusNinEnum];

/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfStatusAnyOf
 */
export interface SubmissionQueryAndInnerAnyOfStatusAnyOf {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$gt'?: SubmissionQueryAndInnerAnyOfStatusAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$gte'?: SubmissionQueryAndInnerAnyOfStatusAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$lt'?: SubmissionQueryAndInnerAnyOfStatusAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$lte'?: SubmissionQueryAndInnerAnyOfStatusAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$ne'?: SubmissionQueryAndInnerAnyOfStatusAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$in'?: Array<SubmissionQueryAndInnerAnyOfStatusAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfStatusAnyOf
     */
    '$nin'?: Array<SubmissionQueryAndInnerAnyOfStatusAnyOfNinEnum>;
}

export const SubmissionQueryAndInnerAnyOfStatusAnyOfGtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfGtEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfGtEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfGtEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfGteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfGteEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfGteEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfGteEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfLtEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfLtEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfLtEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfLtEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfLteEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfLteEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfLteEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfLteEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfNeEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfNeEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfNeEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfNeEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfInEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfInEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfInEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfInEnum];
export const SubmissionQueryAndInnerAnyOfStatusAnyOfNinEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type SubmissionQueryAndInnerAnyOfStatusAnyOfNinEnum = typeof SubmissionQueryAndInnerAnyOfStatusAnyOfNinEnum[keyof typeof SubmissionQueryAndInnerAnyOfStatusAnyOfNinEnum];

/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfSubmittedOn
 */
export interface SubmissionQueryAndInnerAnyOfSubmittedOn {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOn
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
 */
export interface SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf {
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionQueryAndInnerAnyOfSubmittedOnAnyOf
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfTotalScore
 */
export interface SubmissionQueryAndInnerAnyOfTotalScore {
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScore
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
 */
export interface SubmissionQueryAndInnerAnyOfTotalScoreAnyOf {
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubmissionQueryAndInnerAnyOfTotalScoreAnyOf
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SubmissionQuerySort
 */
export interface SubmissionQuerySort {
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'totalScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'fileUrl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'videoUrl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'submittedOn'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionQuerySort
     */
    'questId'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'auth0Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'oldPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: UserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLoginOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLoginIP'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'whatsAppPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'university'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'faculty'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'studyYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'interestFields'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'jobState'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'experienceYears'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'githubHandle'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'points'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'behanceProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'linkedinProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'where_you_know_us'?: string;
}

export const UserRoleEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 * 
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'auth0Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'oldPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'role'?: UserDataRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'avatar'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserData
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'whatsAppPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'university'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'faculty'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'studyYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'interestFields'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'jobState'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserData
     */
    'experienceYears'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'githubHandle'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserData
     */
    'points'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'behanceProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'linkedinProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'where_you_know_us'?: string;
}

export const UserDataRoleEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserDataRoleEnum = typeof UserDataRoleEnum[keyof typeof UserDataRoleEnum];

/**
 * 
 * @export
 * @interface UserPagination
 */
export interface UserPagination {
    /**
     * 
     * @type {number}
     * @memberof UserPagination
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPagination
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPagination
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPagination
     */
    'data'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UserPatch
 */
export interface UserPatch {
    /**
     * 
     * @type {number}
     * @memberof UserPatch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'auth0Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'oldPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'role'?: UserPatchRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'lastLoginOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'lastLoginIP'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPatch
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'whatsAppPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'university'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'faculty'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'studyYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'interestFields'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'jobState'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPatch
     */
    'experienceYears'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'githubHandle'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPatch
     */
    'points'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'behanceProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'linkedinProfileLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatch
     */
    'where_you_know_us'?: string;
}

export const UserPatchRoleEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserPatchRoleEnum = typeof UserPatchRoleEnum[keyof typeof UserPatchRoleEnum];

/**
 * 
 * @export
 * @interface UserQuery
 */
export interface UserQuery {
    /**
     * 
     * @type {UserQuerySort}
     * @memberof UserQuery
     */
    '$sort'?: UserQuerySort;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQuery
     */
    '$select'?: Array<UserQuerySelectEnum>;
    /**
     * 
     * @type {Array<UserQueryAndInner>}
     * @memberof UserQuery
     */
    '$and'?: Array<UserQueryAndInner>;
    /**
     * 
     * @type {Array<UserQueryAndInnerAnyOf>}
     * @memberof UserQuery
     */
    '$or'?: Array<UserQueryAndInnerAnyOf>;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof UserQuery
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'auth0Id'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfEmail}
     * @memberof UserQuery
     */
    'email'?: UserQueryAndInnerAnyOfEmail;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'nickname'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfRole}
     * @memberof UserQuery
     */
    'role'?: UserQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'country'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'city'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfIsActive}
     * @memberof UserQuery
     */
    'isActive'?: UserQueryAndInnerAnyOfIsActive;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'whatsAppPhoneNumber'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'university'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'faculty'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'studyYear'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'interestFields'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'companyName'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'jobState'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQuery
     */
    'experienceYears'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'githubHandle'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQuery
     */
    'points'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQuery
     */
    'behanceProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQuery
     */
    'linkedinProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQuery
     */
    'where_you_know_us'?: QuestQueryAndInnerAnyOfTitle;
}

export const UserQuerySelectEnum = {
    Id: 'id',
    Name: 'name',
    Auth0Id: 'auth0Id',
    Email: 'email',
    Nickname: 'nickname',
    Role: 'role',
    Country: 'country',
    City: 'city',
    IsActive: 'isActive',
    WhatsAppPhoneNumber: 'whatsAppPhoneNumber',
    University: 'university',
    Faculty: 'faculty',
    StudyYear: 'studyYear',
    InterestFields: 'interestFields',
    CompanyName: 'companyName',
    JobState: 'jobState',
    ExperienceYears: 'experienceYears',
    GithubHandle: 'githubHandle',
    Points: 'points',
    BehanceProfileLink: 'behanceProfileLink',
    LinkedinProfileLink: 'linkedinProfileLink',
    WhereYouKnowUs: 'where_you_know_us'
} as const;

export type UserQuerySelectEnum = typeof UserQuerySelectEnum[keyof typeof UserQuerySelectEnum];

/**
 * 
 * @export
 * @interface UserQueryAndInner
 */
export interface UserQueryAndInner {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof UserQueryAndInner
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'auth0Id'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfEmail}
     * @memberof UserQueryAndInner
     */
    'email'?: UserQueryAndInnerAnyOfEmail;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'nickname'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfRole}
     * @memberof UserQueryAndInner
     */
    'role'?: UserQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'country'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'city'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfIsActive}
     * @memberof UserQueryAndInner
     */
    'isActive'?: UserQueryAndInnerAnyOfIsActive;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'whatsAppPhoneNumber'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'university'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'faculty'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'studyYear'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'interestFields'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'companyName'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'jobState'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQueryAndInner
     */
    'experienceYears'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'githubHandle'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQueryAndInner
     */
    'points'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQueryAndInner
     */
    'behanceProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQueryAndInner
     */
    'linkedinProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInner
     */
    'where_you_know_us'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {Array<UserQueryAndInnerAnyOf>}
     * @memberof UserQueryAndInner
     */
    '$or': Array<UserQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOf
 */
export interface UserQueryAndInnerAnyOf {
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfId}
     * @memberof UserQueryAndInnerAnyOf
     */
    'id'?: SubmissionQueryAndInnerAnyOfId;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'name'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'auth0Id'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfEmail}
     * @memberof UserQueryAndInnerAnyOf
     */
    'email'?: UserQueryAndInnerAnyOfEmail;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'nickname'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfRole}
     * @memberof UserQueryAndInnerAnyOf
     */
    'role'?: UserQueryAndInnerAnyOfRole;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'country'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'city'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfIsActive}
     * @memberof UserQueryAndInnerAnyOf
     */
    'isActive'?: UserQueryAndInnerAnyOfIsActive;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'whatsAppPhoneNumber'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'university'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'faculty'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'studyYear'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'interestFields'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'companyName'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'jobState'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQueryAndInnerAnyOf
     */
    'experienceYears'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'githubHandle'?: QuestQueryAndInnerAnyOfTitle;
    /**
     * 
     * @type {UserQueryAndInnerAnyOfExperienceYears}
     * @memberof UserQueryAndInnerAnyOf
     */
    'points'?: UserQueryAndInnerAnyOfExperienceYears;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQueryAndInnerAnyOf
     */
    'behanceProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {SubmissionQueryAndInnerAnyOfFileUrl}
     * @memberof UserQueryAndInnerAnyOf
     */
    'linkedinProfileLink'?: SubmissionQueryAndInnerAnyOfFileUrl;
    /**
     * 
     * @type {QuestQueryAndInnerAnyOfTitle}
     * @memberof UserQueryAndInnerAnyOf
     */
    'where_you_know_us'?: QuestQueryAndInnerAnyOfTitle;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOf1
 */
export interface UserQueryAndInnerAnyOf1 {
    /**
     * 
     * @type {Array<UserQueryAndInnerAnyOf>}
     * @memberof UserQueryAndInnerAnyOf1
     */
    '$or': Array<UserQueryAndInnerAnyOf>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfEmail
 */
export interface UserQueryAndInnerAnyOfEmail {
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfEmail
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfEmailAnyOf
 */
export interface UserQueryAndInnerAnyOfEmailAnyOf {
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$gt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$gte'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$lt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$lte'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$ne'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$in'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfEmailAnyOf
     */
    '$nin'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfExperienceYears
 */
export interface UserQueryAndInnerAnyOfExperienceYears {
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryAndInnerAnyOfExperienceYears
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfExperienceYearsAnyOf
 */
export interface UserQueryAndInnerAnyOfExperienceYearsAnyOf {
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$gt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$gte'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$lt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$lte'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$ne'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$in'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryAndInnerAnyOfExperienceYearsAnyOf
     */
    '$nin'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfIsActive
 */
export interface UserQueryAndInnerAnyOfIsActive {
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$gt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$gte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$lt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$lte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$ne'?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$in'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof UserQueryAndInnerAnyOfIsActive
     */
    '$nin'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfIsActiveAnyOf
 */
export interface UserQueryAndInnerAnyOfIsActiveAnyOf {
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$gt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$gte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$lt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$lte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$ne'?: boolean;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$in'?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof UserQueryAndInnerAnyOfIsActiveAnyOf
     */
    '$nin'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfRole
 */
export interface UserQueryAndInnerAnyOfRole {
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$gt'?: UserQueryAndInnerAnyOfRoleGtEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$gte'?: UserQueryAndInnerAnyOfRoleGteEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$lt'?: UserQueryAndInnerAnyOfRoleLtEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$lte'?: UserQueryAndInnerAnyOfRoleLteEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$ne'?: UserQueryAndInnerAnyOfRoleNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$in'?: Array<UserQueryAndInnerAnyOfRoleInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfRole
     */
    '$nin'?: Array<UserQueryAndInnerAnyOfRoleNinEnum>;
}

export const UserQueryAndInnerAnyOfRoleGtEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleGtEnum = typeof UserQueryAndInnerAnyOfRoleGtEnum[keyof typeof UserQueryAndInnerAnyOfRoleGtEnum];
export const UserQueryAndInnerAnyOfRoleGteEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleGteEnum = typeof UserQueryAndInnerAnyOfRoleGteEnum[keyof typeof UserQueryAndInnerAnyOfRoleGteEnum];
export const UserQueryAndInnerAnyOfRoleLtEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleLtEnum = typeof UserQueryAndInnerAnyOfRoleLtEnum[keyof typeof UserQueryAndInnerAnyOfRoleLtEnum];
export const UserQueryAndInnerAnyOfRoleLteEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleLteEnum = typeof UserQueryAndInnerAnyOfRoleLteEnum[keyof typeof UserQueryAndInnerAnyOfRoleLteEnum];
export const UserQueryAndInnerAnyOfRoleNeEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleNeEnum = typeof UserQueryAndInnerAnyOfRoleNeEnum[keyof typeof UserQueryAndInnerAnyOfRoleNeEnum];
export const UserQueryAndInnerAnyOfRoleInEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleInEnum = typeof UserQueryAndInnerAnyOfRoleInEnum[keyof typeof UserQueryAndInnerAnyOfRoleInEnum];
export const UserQueryAndInnerAnyOfRoleNinEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleNinEnum = typeof UserQueryAndInnerAnyOfRoleNinEnum[keyof typeof UserQueryAndInnerAnyOfRoleNinEnum];

/**
 * 
 * @export
 * @interface UserQueryAndInnerAnyOfRoleAnyOf
 */
export interface UserQueryAndInnerAnyOfRoleAnyOf {
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$gt'?: UserQueryAndInnerAnyOfRoleAnyOfGtEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$gte'?: UserQueryAndInnerAnyOfRoleAnyOfGteEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$lt'?: UserQueryAndInnerAnyOfRoleAnyOfLtEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$lte'?: UserQueryAndInnerAnyOfRoleAnyOfLteEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$ne'?: UserQueryAndInnerAnyOfRoleAnyOfNeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$in'?: Array<UserQueryAndInnerAnyOfRoleAnyOfInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserQueryAndInnerAnyOfRoleAnyOf
     */
    '$nin'?: Array<UserQueryAndInnerAnyOfRoleAnyOfNinEnum>;
}

export const UserQueryAndInnerAnyOfRoleAnyOfGtEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfGtEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfGtEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfGtEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfGteEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfGteEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfGteEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfGteEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfLtEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfLtEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfLtEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfLtEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfLteEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfLteEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfLteEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfLteEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfNeEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfNeEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfNeEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfNeEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfInEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfInEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfInEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfInEnum];
export const UserQueryAndInnerAnyOfRoleAnyOfNinEnum = {
    Admin: 'admin',
    Client: 'client',
    Contestant: 'contestant',
    Reviewer: 'reviewer'
} as const;

export type UserQueryAndInnerAnyOfRoleAnyOfNinEnum = typeof UserQueryAndInnerAnyOfRoleAnyOfNinEnum[keyof typeof UserQueryAndInnerAnyOfRoleAnyOfNinEnum];

/**
 * 
 * @export
 * @interface UserQuerySort
 */
export interface UserQuerySort {
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'name'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'auth0Id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'email'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'nickname'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'role'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'country'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'city'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'isActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'whatsAppPhoneNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'university'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'faculty'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'studyYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'interestFields'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'companyName'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'jobState'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'experienceYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'githubHandle'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'points'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'behanceProfileLink'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'linkedinProfileLink'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserQuerySort
     */
    'where_you_know_us'?: number;
}
/**
 * @type UsersPostRequest
 * @export
 */
export type UsersPostRequest = Array<User> | User;


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {string} accessToken accessToken of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationAccessTokenDelete: async (accessToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('authenticationAccessTokenDelete', 'accessToken', accessToken)
            const localVarPath = `/authentication/{accessToken}`
                .replace(`{${"accessToken"}}`, encodeURIComponent(String(accessToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationPost: async (authRequest: AuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('authenticationPost', 'authRequest', authRequest)
            const localVarPath = `/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {string} accessToken accessToken of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationAccessTokenDelete(accessToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationAccessTokenDelete(accessToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationAccessTokenDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationPost(authRequest: AuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationPost(authRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {string} accessToken accessToken of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationAccessTokenDelete(accessToken: string, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authenticationAccessTokenDelete(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout the currently logged in user
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationDelete(options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authenticationDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationPost(authRequest: AuthRequest, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authenticationPost(authRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Logout the currently logged in user
     * @summary 
     * @param {string} accessToken accessToken of the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationAccessTokenDelete(accessToken: string, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationAccessTokenDelete(accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout the currently logged in user
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationDelete(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationPost(authRequest: AuthRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationPost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BillingInfoApi - axios parameter creator
 * @export
 */
export const BillingInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoDelete: async (filter?: BillingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: BillingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of billing-info to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInfoIdDelete', 'id', id)
            const localVarPath = `/billing-info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of billing-info to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInfoIdGet', 'id', id)
            const localVarPath = `/billing-info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdPatch: async (id: number, body: BillingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInfoIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('billingInfoIdPatch', 'body', body)
            const localVarPath = `/billing-info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdPut: async (id: number, body: BillingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInfoIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('billingInfoIdPut', 'body', body)
            const localVarPath = `/billing-info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} body 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPatch: async (body: BillingInfo, filter?: BillingInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('billingInfoPatch', 'body', body)
            const localVarPath = `/billing-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {BillingInfo | BillingInfoList} billingInfoBillingInfoList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPost: async (billingInfoBillingInfoList: BillingInfo | BillingInfoList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInfoBillingInfoList' is not null or undefined
            assertParamExists('billingInfoPost', 'billingInfoBillingInfoList', billingInfoBillingInfoList)
            const localVarPath = `/billing-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInfoBillingInfoList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {BillingInfoList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPut: async (body: BillingInfoList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('billingInfoPut', 'body', body)
            const localVarPath = `/billing-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingInfoApi - functional programming interface
 * @export
 */
export const BillingInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoDelete(filter?: BillingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoGet($limit?: number, $skip?: number, $sort?: object, filter?: BillingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfoPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of billing-info to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of billing-info to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoIdPatch(id: number, body: BillingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoIdPut(id: number, body: BillingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} body 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoPatch(body: BillingInfo, filter?: BillingInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {BillingInfo | BillingInfoList} billingInfoBillingInfoList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoPost(billingInfoBillingInfoList: BillingInfo | BillingInfoList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfoPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoPost(billingInfoBillingInfoList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {BillingInfoList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInfoPut(body: BillingInfoList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInfoPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingInfoApi.billingInfoPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BillingInfoApi - factory interface
 * @export
 */
export const BillingInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingInfoApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoDelete(filter?: BillingInfo, options?: any): AxiosPromise<BillingInfoList> {
            return localVarFp.billingInfoDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoGet($limit?: number, $skip?: number, $sort?: object, filter?: BillingInfo, options?: any): AxiosPromise<BillingInfoPagination> {
            return localVarFp.billingInfoGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of billing-info to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdDelete(id: number, options?: any): AxiosPromise<BillingInfo> {
            return localVarFp.billingInfoIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of billing-info to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdGet(id: number, options?: any): AxiosPromise<BillingInfo> {
            return localVarFp.billingInfoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdPatch(id: number, body: BillingInfo, options?: any): AxiosPromise<BillingInfo> {
            return localVarFp.billingInfoIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of billing-info to update
         * @param {BillingInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoIdPut(id: number, body: BillingInfo, options?: any): AxiosPromise<BillingInfo> {
            return localVarFp.billingInfoIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {BillingInfo} body 
         * @param {BillingInfo} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPatch(body: BillingInfo, filter?: BillingInfo, options?: any): AxiosPromise<BillingInfoList> {
            return localVarFp.billingInfoPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {BillingInfo | BillingInfoList} billingInfoBillingInfoList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPost(billingInfoBillingInfoList: BillingInfo | BillingInfoList, options?: any): AxiosPromise<BillingInfoPostRequest> {
            return localVarFp.billingInfoPost(billingInfoBillingInfoList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {BillingInfoList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInfoPut(body: BillingInfoList, options?: any): AxiosPromise<BillingInfoList> {
            return localVarFp.billingInfoPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingInfoApi - object-oriented interface
 * @export
 * @class BillingInfoApi
 * @extends {BaseAPI}
 */
export class BillingInfoApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {BillingInfo} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoDelete(filter?: BillingInfo, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {BillingInfo} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoGet($limit?: number, $skip?: number, $sort?: object, filter?: BillingInfo, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of billing-info to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of billing-info to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoIdGet(id: number, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of billing-info to update
     * @param {BillingInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoIdPatch(id: number, body: BillingInfo, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of billing-info to update
     * @param {BillingInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoIdPut(id: number, body: BillingInfo, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {BillingInfo} body 
     * @param {BillingInfo} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoPatch(body: BillingInfo, filter?: BillingInfo, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {BillingInfo | BillingInfoList} billingInfoBillingInfoList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoPost(billingInfoBillingInfoList: BillingInfo | BillingInfoList, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoPost(billingInfoBillingInfoList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {BillingInfoList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingInfoApi
     */
    public billingInfoPut(body: BillingInfoList, options?: RawAxiosRequestConfig) {
        return BillingInfoApiFp(this.configuration).billingInfoPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete: async (filter?: Category, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {CategoryQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async ($limit?: number, $skip?: number, filter?: CategoryQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Category to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdDelete', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Category to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdGet', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryPatch} categoryPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPatch: async (id: number, categoryPatch: CategoryPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdPatch', 'id', id)
            // verify required parameter 'categoryPatch' is not null or undefined
            assertParamExists('categoriesIdPatch', 'categoryPatch', categoryPatch)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryData} categoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPut: async (id: number, categoryData: CategoryData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdPut', 'id', id)
            // verify required parameter 'categoryData' is not null or undefined
            assertParamExists('categoriesIdPut', 'categoryData', categoryData)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Category} category 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPatch: async (category: Category, filter?: Category, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('categoriesPatch', 'category', category)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(category, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {CategoriesPostRequest} categoriesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPost: async (categoriesPostRequest: CategoriesPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriesPostRequest' is not null or undefined
            assertParamExists('categoriesPost', 'categoriesPostRequest', categoriesPostRequest)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Category>} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPut: async (category: Array<Category>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('categoriesPut', 'category', category)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(category, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesDelete(filter?: Category, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {CategoryQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet($limit?: number, $skip?: number, filter?: CategoryQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet($limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Category to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Category to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryPatch} categoryPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdPatch(id: number, categoryPatch: CategoryPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdPatch(id, categoryPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryData} categoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdPut(id: number, categoryData: CategoryData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdPut(id, categoryData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Category} category 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPatch(category: Category, filter?: Category, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPatch(category, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {CategoriesPostRequest} categoriesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPost(categoriesPostRequest: CategoriesPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPost(categoriesPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Category>} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPut(category: Array<Category>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPut(category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete(filter?: Category, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {CategoryQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet($limit?: number, $skip?: number, filter?: CategoryQuery, options?: any): AxiosPromise<CategoryPagination> {
            return localVarFp.categoriesGet($limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Category to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdDelete(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Category to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdGet(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryPatch} categoryPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPatch(id: number, categoryPatch: CategoryPatch, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdPatch(id, categoryPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Category to update
         * @param {CategoryData} categoryData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdPut(id: number, categoryData: CategoryData, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesIdPut(id, categoryData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Category} category 
         * @param {Category} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPatch(category: Category, filter?: Category, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesPatch(category, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {CategoriesPostRequest} categoriesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPost(categoriesPostRequest: CategoriesPostRequest, options?: any): AxiosPromise<CategoriesPostRequest> {
            return localVarFp.categoriesPost(categoriesPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Category>} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPut(category: Array<Category>, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesPut(category, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Category} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesDelete(filter?: Category, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {CategoryQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGet($limit?: number, $skip?: number, filter?: CategoryQuery, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesGet($limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of Category to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of Category to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Category to update
     * @param {CategoryPatch} categoryPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesIdPatch(id: number, categoryPatch: CategoryPatch, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesIdPatch(id, categoryPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Category to update
     * @param {CategoryData} categoryData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesIdPut(id: number, categoryData: CategoryData, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesIdPut(id, categoryData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Category} category 
     * @param {Category} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesPatch(category: Category, filter?: Category, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesPatch(category, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {CategoriesPostRequest} categoriesPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesPost(categoriesPostRequest: CategoriesPostRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesPost(categoriesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {Array<Category>} category 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesPut(category: Array<Category>, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesPut(category, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsDelete: async (filter?: Comments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: Comments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of comments to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('commentsIdDelete', 'id', id)
            const localVarPath = `/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of comments to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('commentsIdGet', 'id', id)
            const localVarPath = `/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdPatch: async (id: number, body: Comments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('commentsIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commentsIdPatch', 'body', body)
            const localVarPath = `/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdPut: async (id: number, body: Comments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('commentsIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commentsIdPut', 'body', body)
            const localVarPath = `/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Comments} body 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPatch: async (body: Comments, filter?: Comments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commentsPatch', 'body', body)
            const localVarPath = `/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Comments | CommentsList} commentsCommentsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPost: async (commentsCommentsList: Comments | CommentsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentsCommentsList' is not null or undefined
            assertParamExists('commentsPost', 'commentsCommentsList', commentsCommentsList)
            const localVarPath = `/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentsCommentsList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {CommentsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPut: async (body: CommentsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('commentsPut', 'body', body)
            const localVarPath = `/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsDelete(filter?: Comments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsGet($limit?: number, $skip?: number, $sort?: object, filter?: Comments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of comments to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of comments to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsIdPatch(id: number, body: Comments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsIdPut(id: number, body: Comments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Comments} body 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsPatch(body: Comments, filter?: Comments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Comments | CommentsList} commentsCommentsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsPost(commentsCommentsList: Comments | CommentsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsPost(commentsCommentsList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {CommentsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsPut(body: CommentsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.commentsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsDelete(filter?: Comments, options?: any): AxiosPromise<CommentsList> {
            return localVarFp.commentsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsGet($limit?: number, $skip?: number, $sort?: object, filter?: Comments, options?: any): AxiosPromise<CommentsPagination> {
            return localVarFp.commentsGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of comments to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdDelete(id: number, options?: any): AxiosPromise<Comments> {
            return localVarFp.commentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of comments to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdGet(id: number, options?: any): AxiosPromise<Comments> {
            return localVarFp.commentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdPatch(id: number, body: Comments, options?: any): AxiosPromise<Comments> {
            return localVarFp.commentsIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of comments to update
         * @param {Comments} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsIdPut(id: number, body: Comments, options?: any): AxiosPromise<Comments> {
            return localVarFp.commentsIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Comments} body 
         * @param {Comments} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPatch(body: Comments, filter?: Comments, options?: any): AxiosPromise<CommentsList> {
            return localVarFp.commentsPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Comments | CommentsList} commentsCommentsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPost(commentsCommentsList: Comments | CommentsList, options?: any): AxiosPromise<CommentsPostRequest> {
            return localVarFp.commentsPost(commentsCommentsList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {CommentsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsPut(body: CommentsList, options?: any): AxiosPromise<CommentsList> {
            return localVarFp.commentsPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Comments} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsDelete(filter?: Comments, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {Comments} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsGet($limit?: number, $skip?: number, $sort?: object, filter?: Comments, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of comments to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of comments to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of comments to update
     * @param {Comments} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsIdPatch(id: number, body: Comments, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of comments to update
     * @param {Comments} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsIdPut(id: number, body: Comments, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Comments} body 
     * @param {Comments} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsPatch(body: Comments, filter?: Comments, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {Comments | CommentsList} commentsCommentsList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsPost(commentsCommentsList: Comments | CommentsList, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsPost(commentsCommentsList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {CommentsList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public commentsPut(body: CommentsList, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).commentsPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackDelete: async (filter?: Feedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: Feedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of feedback to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackIdDelete', 'id', id)
            const localVarPath = `/feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of feedback to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackIdGet', 'id', id)
            const localVarPath = `/feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdPatch: async (id: number, body: Feedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('feedbackIdPatch', 'body', body)
            const localVarPath = `/feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdPut: async (id: number, body: Feedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('feedbackIdPut', 'body', body)
            const localVarPath = `/feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} body 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPatch: async (body: Feedback, filter?: Feedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('feedbackPatch', 'body', body)
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Feedback | FeedbackList} feedbackFeedbackList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPost: async (feedbackFeedbackList: Feedback | FeedbackList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackFeedbackList' is not null or undefined
            assertParamExists('feedbackPost', 'feedbackFeedbackList', feedbackFeedbackList)
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedbackFeedbackList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {FeedbackList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPut: async (body: FeedbackList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('feedbackPut', 'body', body)
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackDelete(filter?: Feedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackGet($limit?: number, $skip?: number, $sort?: object, filter?: Feedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of feedback to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of feedback to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackIdPatch(id: number, body: Feedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackIdPut(id: number, body: Feedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} body 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackPatch(body: Feedback, filter?: Feedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Feedback | FeedbackList} feedbackFeedbackList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackPost(feedbackFeedbackList: Feedback | FeedbackList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackPost(feedbackFeedbackList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {FeedbackList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackPut(body: FeedbackList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbackApi.feedbackPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackDelete(filter?: Feedback, options?: any): AxiosPromise<FeedbackList> {
            return localVarFp.feedbackDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackGet($limit?: number, $skip?: number, $sort?: object, filter?: Feedback, options?: any): AxiosPromise<FeedbackPagination> {
            return localVarFp.feedbackGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of feedback to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdDelete(id: number, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of feedback to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdGet(id: number, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdPatch(id: number, body: Feedback, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of feedback to update
         * @param {Feedback} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackIdPut(id: number, body: Feedback, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Feedback} body 
         * @param {Feedback} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPatch(body: Feedback, filter?: Feedback, options?: any): AxiosPromise<FeedbackList> {
            return localVarFp.feedbackPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Feedback | FeedbackList} feedbackFeedbackList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPost(feedbackFeedbackList: Feedback | FeedbackList, options?: any): AxiosPromise<FeedbackPostRequest> {
            return localVarFp.feedbackPost(feedbackFeedbackList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {FeedbackList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPut(body: FeedbackList, options?: any): AxiosPromise<FeedbackList> {
            return localVarFp.feedbackPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Feedback} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackDelete(filter?: Feedback, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {Feedback} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackGet($limit?: number, $skip?: number, $sort?: object, filter?: Feedback, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of feedback to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of feedback to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackIdGet(id: number, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of feedback to update
     * @param {Feedback} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackIdPatch(id: number, body: Feedback, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of feedback to update
     * @param {Feedback} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackIdPut(id: number, body: Feedback, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Feedback} body 
     * @param {Feedback} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackPatch(body: Feedback, filter?: Feedback, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {Feedback | FeedbackList} feedbackFeedbackList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackPost(feedbackFeedbackList: Feedback | FeedbackList, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackPost(feedbackFeedbackList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {FeedbackList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackPut(body: FeedbackList, options?: RawAxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MyApi - axios parameter creator
 * @export
 */
export const MyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsDelete: async (filter?: Submission, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/my/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsGet: async ($limit?: number, $skip?: number, filter?: SubmissionQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/my/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mySubmissionsIdDelete', 'id', id)
            const localVarPath = `/my/submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mySubmissionsIdGet', 'id', id)
            const localVarPath = `/my/submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdPatch: async (id: number, submissionPatch: SubmissionPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mySubmissionsIdPatch', 'id', id)
            // verify required parameter 'submissionPatch' is not null or undefined
            assertParamExists('mySubmissionsIdPatch', 'submissionPatch', submissionPatch)
            const localVarPath = `/my/submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdPut: async (id: number, submissionData: SubmissionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mySubmissionsIdPut', 'id', id)
            // verify required parameter 'submissionData' is not null or undefined
            assertParamExists('mySubmissionsIdPut', 'submissionData', submissionData)
            const localVarPath = `/my/submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPatch: async (submission: Submission, filter?: Submission, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submission' is not null or undefined
            assertParamExists('mySubmissionsPatch', 'submission', submission)
            const localVarPath = `/my/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPost: async (questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questsQuestIdSubmissionsPostRequest' is not null or undefined
            assertParamExists('mySubmissionsPost', 'questsQuestIdSubmissionsPostRequest', questsQuestIdSubmissionsPostRequest)
            const localVarPath = `/my/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questsQuestIdSubmissionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPut: async (submission: Array<Submission>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submission' is not null or undefined
            assertParamExists('mySubmissionsPut', 'submission', submission)
            const localVarPath = `/my/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyApi - functional programming interface
 * @export
 */
export const MyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsDelete(filter?: Submission, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsGet($limit?: number, $skip?: number, filter?: SubmissionQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsGet($limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsIdPatch(id: number, submissionPatch: SubmissionPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsIdPatch(id, submissionPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsIdPut(id: number, submissionData: SubmissionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsIdPut(id, submissionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsPatch(submission: Submission, filter?: Submission, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsPatch(submission, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsPost(questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsQuestIdSubmissionsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsPost(questsQuestIdSubmissionsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mySubmissionsPut(submission: Array<Submission>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mySubmissionsPut(submission, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MyApi.mySubmissionsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MyApi - factory interface
 * @export
 */
export const MyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsDelete(filter?: Submission, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.mySubmissionsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsGet($limit?: number, $skip?: number, filter?: SubmissionQuery, options?: any): AxiosPromise<SubmissionPagination> {
            return localVarFp.mySubmissionsGet($limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdDelete(id: number, options?: any): AxiosPromise<Submission> {
            return localVarFp.mySubmissionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdGet(id: number, options?: any): AxiosPromise<Submission> {
            return localVarFp.mySubmissionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdPatch(id: number, submissionPatch: SubmissionPatch, options?: any): AxiosPromise<Submission> {
            return localVarFp.mySubmissionsIdPatch(id, submissionPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsIdPut(id: number, submissionData: SubmissionData, options?: any): AxiosPromise<Submission> {
            return localVarFp.mySubmissionsIdPut(id, submissionData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPatch(submission: Submission, filter?: Submission, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.mySubmissionsPatch(submission, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPost(questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: any): AxiosPromise<QuestsQuestIdSubmissionsPostRequest> {
            return localVarFp.mySubmissionsPost(questsQuestIdSubmissionsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mySubmissionsPut(submission: Array<Submission>, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.mySubmissionsPut(submission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyApi - object-oriented interface
 * @export
 * @class MyApi
 * @extends {BaseAPI}
 */
export class MyApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Submission} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsDelete(filter?: Submission, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {SubmissionQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsGet($limit?: number, $skip?: number, filter?: SubmissionQuery, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsGet($limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of Submission to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of Submission to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Submission to update
     * @param {SubmissionPatch} submissionPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsIdPatch(id: number, submissionPatch: SubmissionPatch, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsIdPatch(id, submissionPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Submission to update
     * @param {SubmissionData} submissionData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsIdPut(id: number, submissionData: SubmissionData, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsIdPut(id, submissionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Submission} submission 
     * @param {Submission} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsPatch(submission: Submission, filter?: Submission, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsPatch(submission, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsPost(questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsPost(questsQuestIdSubmissionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {Array<Submission>} submission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public mySubmissionsPut(submission: Array<Submission>, options?: RawAxiosRequestConfig) {
        return MyApiFp(this.configuration).mySubmissionsPut(submission, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDelete: async (filter?: Notifications, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: Notifications, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of notifications to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationsIdDelete', 'id', id)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of notifications to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationsIdGet', 'id', id)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdPatch: async (id: number, body: Notifications, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationsIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('notificationsIdPatch', 'body', body)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdPut: async (id: number, body: Notifications, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationsIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('notificationsIdPut', 'body', body)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} body 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPatch: async (body: Notifications, filter?: Notifications, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('notificationsPatch', 'body', body)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Notifications | NotificationsList} notificationsNotificationsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPost: async (notificationsNotificationsList: Notifications | NotificationsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationsNotificationsList' is not null or undefined
            assertParamExists('notificationsPost', 'notificationsNotificationsList', notificationsNotificationsList)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsNotificationsList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {NotificationsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPut: async (body: NotificationsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('notificationsPut', 'body', body)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsDelete(filter?: Notifications, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsGet($limit?: number, $skip?: number, $sort?: object, filter?: Notifications, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of notifications to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of notifications to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsIdPatch(id: number, body: Notifications, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsIdPut(id: number, body: Notifications, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} body 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsPatch(body: Notifications, filter?: Notifications, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Notifications | NotificationsList} notificationsNotificationsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsPost(notificationsNotificationsList: Notifications | NotificationsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsPost(notificationsNotificationsList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {NotificationsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsPut(body: NotificationsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsDelete(filter?: Notifications, options?: any): AxiosPromise<NotificationsList> {
            return localVarFp.notificationsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet($limit?: number, $skip?: number, $sort?: object, filter?: Notifications, options?: any): AxiosPromise<NotificationsPagination> {
            return localVarFp.notificationsGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of notifications to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdDelete(id: number, options?: any): AxiosPromise<Notifications> {
            return localVarFp.notificationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of notifications to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdGet(id: number, options?: any): AxiosPromise<Notifications> {
            return localVarFp.notificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdPatch(id: number, body: Notifications, options?: any): AxiosPromise<Notifications> {
            return localVarFp.notificationsIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of notifications to update
         * @param {Notifications} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdPut(id: number, body: Notifications, options?: any): AxiosPromise<Notifications> {
            return localVarFp.notificationsIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Notifications} body 
         * @param {Notifications} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPatch(body: Notifications, filter?: Notifications, options?: any): AxiosPromise<NotificationsList> {
            return localVarFp.notificationsPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Notifications | NotificationsList} notificationsNotificationsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPost(notificationsNotificationsList: Notifications | NotificationsList, options?: any): AxiosPromise<NotificationsPostRequest> {
            return localVarFp.notificationsPost(notificationsNotificationsList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {NotificationsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsPut(body: NotificationsList, options?: any): AxiosPromise<NotificationsList> {
            return localVarFp.notificationsPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Notifications} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsDelete(filter?: Notifications, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {Notifications} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsGet($limit?: number, $skip?: number, $sort?: object, filter?: Notifications, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of notifications to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of notifications to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of notifications to update
     * @param {Notifications} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsIdPatch(id: number, body: Notifications, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of notifications to update
     * @param {Notifications} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsIdPut(id: number, body: Notifications, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Notifications} body 
     * @param {Notifications} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsPatch(body: Notifications, filter?: Notifications, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {Notifications | NotificationsList} notificationsNotificationsList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsPost(notificationsNotificationsList: Notifications | NotificationsList, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsPost(notificationsNotificationsList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {NotificationsList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsPut(body: NotificationsList, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationsPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrgsApi - axios parameter creator
 * @export
 */
export const OrgsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsDelete: async (filter?: Org, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {OrgQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsGet: async ($limit?: number, $skip?: number, filter?: OrgQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Org to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orgsIdDelete', 'id', id)
            const localVarPath = `/orgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Org to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orgsIdGet', 'id', id)
            const localVarPath = `/orgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgPatch} orgPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdPatch: async (id: number, orgPatch: OrgPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orgsIdPatch', 'id', id)
            // verify required parameter 'orgPatch' is not null or undefined
            assertParamExists('orgsIdPatch', 'orgPatch', orgPatch)
            const localVarPath = `/orgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgData} orgData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdPut: async (id: number, orgData: OrgData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orgsIdPut', 'id', id)
            // verify required parameter 'orgData' is not null or undefined
            assertParamExists('orgsIdPut', 'orgData', orgData)
            const localVarPath = `/orgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Org} org 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPatch: async (org: Org, filter?: Org, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'org' is not null or undefined
            assertParamExists('orgsPatch', 'org', org)
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(org, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {OrgsPostRequest} orgsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPost: async (orgsPostRequest: OrgsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgsPostRequest' is not null or undefined
            assertParamExists('orgsPost', 'orgsPostRequest', orgsPostRequest)
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Org>} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPut: async (org: Array<Org>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'org' is not null or undefined
            assertParamExists('orgsPut', 'org', org)
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(org, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgsApi - functional programming interface
 * @export
 */
export const OrgsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsDelete(filter?: Org, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Org>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {OrgQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsGet($limit?: number, $skip?: number, filter?: OrgQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsGet($limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Org to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Org to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgPatch} orgPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsIdPatch(id: number, orgPatch: OrgPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsIdPatch(id, orgPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgData} orgData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsIdPut(id: number, orgData: OrgData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Org>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsIdPut(id, orgData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Org} org 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsPatch(org: Org, filter?: Org, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Org>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsPatch(org, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {OrgsPostRequest} orgsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsPost(orgsPostRequest: OrgsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsPost(orgsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Org>} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsPut(org: Array<Org>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Org>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsPut(org, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgsApi.orgsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrgsApi - factory interface
 * @export
 */
export const OrgsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgsApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsDelete(filter?: Org, options?: any): AxiosPromise<Array<Org>> {
            return localVarFp.orgsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {OrgQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsGet($limit?: number, $skip?: number, filter?: OrgQuery, options?: any): AxiosPromise<OrgPagination> {
            return localVarFp.orgsGet($limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Org to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdDelete(id: number, options?: any): AxiosPromise<Org> {
            return localVarFp.orgsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Org to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdGet(id: number, options?: any): AxiosPromise<Org> {
            return localVarFp.orgsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgPatch} orgPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdPatch(id: number, orgPatch: OrgPatch, options?: any): AxiosPromise<Org> {
            return localVarFp.orgsIdPatch(id, orgPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Org to update
         * @param {OrgData} orgData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsIdPut(id: number, orgData: OrgData, options?: any): AxiosPromise<Org> {
            return localVarFp.orgsIdPut(id, orgData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Org} org 
         * @param {Org} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPatch(org: Org, filter?: Org, options?: any): AxiosPromise<Array<Org>> {
            return localVarFp.orgsPatch(org, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {OrgsPostRequest} orgsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPost(orgsPostRequest: OrgsPostRequest, options?: any): AxiosPromise<OrgsPostRequest> {
            return localVarFp.orgsPost(orgsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Org>} org 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPut(org: Array<Org>, options?: any): AxiosPromise<Array<Org>> {
            return localVarFp.orgsPut(org, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgsApi - object-oriented interface
 * @export
 * @class OrgsApi
 * @extends {BaseAPI}
 */
export class OrgsApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Org} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsDelete(filter?: Org, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {OrgQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsGet($limit?: number, $skip?: number, filter?: OrgQuery, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsGet($limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of Org to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of Org to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Org to update
     * @param {OrgPatch} orgPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsIdPatch(id: number, orgPatch: OrgPatch, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsIdPatch(id, orgPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Org to update
     * @param {OrgData} orgData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsIdPut(id: number, orgData: OrgData, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsIdPut(id, orgData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Org} org 
     * @param {Org} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsPatch(org: Org, filter?: Org, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsPatch(org, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {OrgsPostRequest} orgsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsPost(orgsPostRequest: OrgsPostRequest, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsPost(orgsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {Array<Org>} org 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsPut(org: Array<Org>, options?: RawAxiosRequestConfig) {
        return OrgsApiFp(this.configuration).orgsPut(org, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrizesApi - axios parameter creator
 * @export
 */
export const PrizesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesDelete: async (filter?: Prizes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: Prizes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of prizes to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('prizesIdDelete', 'id', id)
            const localVarPath = `/prizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of prizes to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('prizesIdGet', 'id', id)
            const localVarPath = `/prizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdPatch: async (id: number, body: Prizes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('prizesIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('prizesIdPatch', 'body', body)
            const localVarPath = `/prizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdPut: async (id: number, body: Prizes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('prizesIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('prizesIdPut', 'body', body)
            const localVarPath = `/prizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} body 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPatch: async (body: Prizes, filter?: Prizes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('prizesPatch', 'body', body)
            const localVarPath = `/prizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Prizes | PrizesList} prizesPrizesList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPost: async (prizesPrizesList: Prizes | PrizesList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prizesPrizesList' is not null or undefined
            assertParamExists('prizesPost', 'prizesPrizesList', prizesPrizesList)
            const localVarPath = `/prizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prizesPrizesList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {PrizesList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPut: async (body: PrizesList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('prizesPut', 'body', body)
            const localVarPath = `/prizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrizesApi - functional programming interface
 * @export
 */
export const PrizesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrizesApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesDelete(filter?: Prizes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrizesList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesGet($limit?: number, $skip?: number, $sort?: object, filter?: Prizes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrizesPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of prizes to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of prizes to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesIdPatch(id: number, body: Prizes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesIdPut(id: number, body: Prizes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Prizes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} body 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesPatch(body: Prizes, filter?: Prizes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrizesList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Prizes | PrizesList} prizesPrizesList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesPost(prizesPrizesList: Prizes | PrizesList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrizesPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesPost(prizesPrizesList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {PrizesList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prizesPut(body: PrizesList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrizesList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prizesPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PrizesApi.prizesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PrizesApi - factory interface
 * @export
 */
export const PrizesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrizesApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesDelete(filter?: Prizes, options?: any): AxiosPromise<PrizesList> {
            return localVarFp.prizesDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesGet($limit?: number, $skip?: number, $sort?: object, filter?: Prizes, options?: any): AxiosPromise<PrizesPagination> {
            return localVarFp.prizesGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of prizes to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdDelete(id: number, options?: any): AxiosPromise<Prizes> {
            return localVarFp.prizesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of prizes to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdGet(id: number, options?: any): AxiosPromise<Prizes> {
            return localVarFp.prizesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdPatch(id: number, body: Prizes, options?: any): AxiosPromise<Prizes> {
            return localVarFp.prizesIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of prizes to update
         * @param {Prizes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesIdPut(id: number, body: Prizes, options?: any): AxiosPromise<Prizes> {
            return localVarFp.prizesIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Prizes} body 
         * @param {Prizes} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPatch(body: Prizes, filter?: Prizes, options?: any): AxiosPromise<PrizesList> {
            return localVarFp.prizesPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Prizes | PrizesList} prizesPrizesList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPost(prizesPrizesList: Prizes | PrizesList, options?: any): AxiosPromise<PrizesPostRequest> {
            return localVarFp.prizesPost(prizesPrizesList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {PrizesList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prizesPut(body: PrizesList, options?: any): AxiosPromise<PrizesList> {
            return localVarFp.prizesPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrizesApi - object-oriented interface
 * @export
 * @class PrizesApi
 * @extends {BaseAPI}
 */
export class PrizesApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Prizes} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesDelete(filter?: Prizes, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {Prizes} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesGet($limit?: number, $skip?: number, $sort?: object, filter?: Prizes, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of prizes to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of prizes to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesIdGet(id: number, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of prizes to update
     * @param {Prizes} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesIdPatch(id: number, body: Prizes, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of prizes to update
     * @param {Prizes} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesIdPut(id: number, body: Prizes, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Prizes} body 
     * @param {Prizes} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesPatch(body: Prizes, filter?: Prizes, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {Prizes | PrizesList} prizesPrizesList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesPost(prizesPrizesList: Prizes | PrizesList, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesPost(prizesPrizesList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {PrizesList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrizesApi
     */
    public prizesPut(body: PrizesList, options?: RawAxiosRequestConfig) {
        return PrizesApiFp(this.configuration).prizesPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuestsApi - axios parameter creator
 * @export
 */
export const QuestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsDelete: async (filter?: Quest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {QuestQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsGet: async ($limit?: number, $skip?: number, filter?: QuestQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Quest to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsIdDelete', 'id', id)
            const localVarPath = `/quests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Quest to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsIdGet', 'id', id)
            const localVarPath = `/quests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestPatch} questPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdPatch: async (id: number, questPatch: QuestPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsIdPatch', 'id', id)
            // verify required parameter 'questPatch' is not null or undefined
            assertParamExists('questsIdPatch', 'questPatch', questPatch)
            const localVarPath = `/quests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestData} questData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdPut: async (id: number, questData: QuestData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsIdPut', 'id', id)
            // verify required parameter 'questData' is not null or undefined
            assertParamExists('questsIdPut', 'questData', questData)
            const localVarPath = `/quests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Quest} quest 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPatch: async (quest: Quest, filter?: Quest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quest' is not null or undefined
            assertParamExists('questsPatch', 'quest', quest)
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsPostRequest} questsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPost: async (questsPostRequest: QuestsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questsPostRequest' is not null or undefined
            assertParamExists('questsPost', 'questsPostRequest', questsPostRequest)
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Quest>} quest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPut: async (quest: Array<Quest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quest' is not null or undefined
            assertParamExists('questsPut', 'quest', quest)
            const localVarPath = `/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistDelete: async (questId: string, filter?: Registration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistDelete', 'questId', questId)
            const localVarPath = `/quests/{questId}/enlist`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistGet: async (questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistGet', 'questId', questId)
            const localVarPath = `/quests/{questId}/enlist`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdDelete: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdDelete', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdDelete', 'id', id)
            const localVarPath = `/quests/{questId}/enlist/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdGet: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdGet', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdGet', 'id', id)
            const localVarPath = `/quests/{questId}/enlist/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdPatch: async (questId: string, id: number, registrationPatch: RegistrationPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPatch', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPatch', 'id', id)
            // verify required parameter 'registrationPatch' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPatch', 'registrationPatch', registrationPatch)
            const localVarPath = `/quests/{questId}/enlist/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdPut: async (questId: string, id: number, registrationData: RegistrationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPut', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPut', 'id', id)
            // verify required parameter 'registrationData' is not null or undefined
            assertParamExists('questsQuestIdEnlistIdPut', 'registrationData', registrationData)
            const localVarPath = `/quests/{questId}/enlist/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPatch: async (questId: string, registration: Registration, filter?: Registration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistPatch', 'questId', questId)
            // verify required parameter 'registration' is not null or undefined
            assertParamExists('questsQuestIdEnlistPatch', 'registration', registration)
            const localVarPath = `/quests/{questId}/enlist`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPost: async (questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistPost', 'questId', questId)
            // verify required parameter 'questsQuestIdEnlistPostRequest' is not null or undefined
            assertParamExists('questsQuestIdEnlistPost', 'questsQuestIdEnlistPostRequest', questsQuestIdEnlistPostRequest)
            const localVarPath = `/quests/{questId}/enlist`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questsQuestIdEnlistPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPut: async (questId: string, registration: Array<Registration>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdEnlistPut', 'questId', questId)
            // verify required parameter 'registration' is not null or undefined
            assertParamExists('questsQuestIdEnlistPut', 'registration', registration)
            const localVarPath = `/quests/{questId}/enlist`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteDelete: async (questId: string, filter?: Registration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteDelete', 'questId', questId)
            const localVarPath = `/quests/{questId}/invite`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteGet: async (questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteGet', 'questId', questId)
            const localVarPath = `/quests/{questId}/invite`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdDelete: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteIdDelete', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdInviteIdDelete', 'id', id)
            const localVarPath = `/quests/{questId}/invite/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdGet: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteIdGet', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdInviteIdGet', 'id', id)
            const localVarPath = `/quests/{questId}/invite/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdPatch: async (questId: string, id: number, registrationPatch: RegistrationPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPatch', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPatch', 'id', id)
            // verify required parameter 'registrationPatch' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPatch', 'registrationPatch', registrationPatch)
            const localVarPath = `/quests/{questId}/invite/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdPut: async (questId: string, id: number, registrationData: RegistrationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPut', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPut', 'id', id)
            // verify required parameter 'registrationData' is not null or undefined
            assertParamExists('questsQuestIdInviteIdPut', 'registrationData', registrationData)
            const localVarPath = `/quests/{questId}/invite/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePatch: async (questId: string, registration: Registration, filter?: Registration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInvitePatch', 'questId', questId)
            // verify required parameter 'registration' is not null or undefined
            assertParamExists('questsQuestIdInvitePatch', 'registration', registration)
            const localVarPath = `/quests/{questId}/invite`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePost: async (questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInvitePost', 'questId', questId)
            // verify required parameter 'questsQuestIdEnlistPostRequest' is not null or undefined
            assertParamExists('questsQuestIdInvitePost', 'questsQuestIdEnlistPostRequest', questsQuestIdEnlistPostRequest)
            const localVarPath = `/quests/{questId}/invite`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questsQuestIdEnlistPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePut: async (questId: string, registration: Array<Registration>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdInvitePut', 'questId', questId)
            // verify required parameter 'registration' is not null or undefined
            assertParamExists('questsQuestIdInvitePut', 'registration', registration)
            const localVarPath = `/quests/{questId}/invite`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsDelete: async (questId: string, filter?: QuestId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsDelete', 'questId', questId)
            const localVarPath = `/quests/{questId}/reviews`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsGet: async (questId: string, $limit?: number, $skip?: number, $sort?: object, filter?: QuestId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsGet', 'questId', questId)
            const localVarPath = `/quests/{questId}/reviews`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdDelete: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdDelete', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdDelete', 'id', id)
            const localVarPath = `/quests/{questId}/reviews/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdGet: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdGet', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdGet', 'id', id)
            const localVarPath = `/quests/{questId}/reviews/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdPatch: async (questId: string, id: number, body: QuestId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPatch', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPatch', 'body', body)
            const localVarPath = `/quests/{questId}/reviews/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdPut: async (questId: string, id: number, body: QuestId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPut', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('questsQuestIdReviewsIdPut', 'body', body)
            const localVarPath = `/quests/{questId}/reviews/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} body 
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPatch: async (questId: string, body: QuestId, filter?: QuestId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsPatch', 'questId', questId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('questsQuestIdReviewsPatch', 'body', body)
            const localVarPath = `/quests/{questId}/reviews`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId | QuestIdList} questIdQuestIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPost: async (questId: string, questIdQuestIdList: QuestId | QuestIdList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsPost', 'questId', questId)
            // verify required parameter 'questIdQuestIdList' is not null or undefined
            assertParamExists('questsQuestIdReviewsPost', 'questIdQuestIdList', questIdQuestIdList)
            const localVarPath = `/quests/{questId}/reviews`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questIdQuestIdList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestIdList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPut: async (questId: string, body: QuestIdList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdReviewsPut', 'questId', questId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('questsQuestIdReviewsPut', 'body', body)
            const localVarPath = `/quests/{questId}/reviews`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsDelete: async (questId: string, filter?: Submission, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsDelete', 'questId', questId)
            const localVarPath = `/quests/{questId}/submissions`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsGet: async (questId: string, $limit?: number, $skip?: number, filter?: SubmissionQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsGet', 'questId', questId)
            const localVarPath = `/quests/{questId}/submissions`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdDelete: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdDelete', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdDelete', 'id', id)
            const localVarPath = `/quests/{questId}/submissions/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdGet: async (questId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdGet', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdGet', 'id', id)
            const localVarPath = `/quests/{questId}/submissions/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdPatch: async (questId: string, id: number, submissionPatch: SubmissionPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPatch', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPatch', 'id', id)
            // verify required parameter 'submissionPatch' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPatch', 'submissionPatch', submissionPatch)
            const localVarPath = `/quests/{questId}/submissions/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdPut: async (questId: string, id: number, submissionData: SubmissionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPut', 'questId', questId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPut', 'id', id)
            // verify required parameter 'submissionData' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsIdPut', 'submissionData', submissionData)
            const localVarPath = `/quests/{questId}/submissions/{id}`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPatch: async (questId: string, submission: Submission, filter?: Submission, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPatch', 'questId', questId)
            // verify required parameter 'submission' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPatch', 'submission', submission)
            const localVarPath = `/quests/{questId}/submissions`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPost: async (questId: string, questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPost', 'questId', questId)
            // verify required parameter 'questsQuestIdSubmissionsPostRequest' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPost', 'questsQuestIdSubmissionsPostRequest', questsQuestIdSubmissionsPostRequest)
            const localVarPath = `/quests/{questId}/submissions`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questsQuestIdSubmissionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPut: async (questId: string, submission: Array<Submission>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questId' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPut', 'questId', questId)
            // verify required parameter 'submission' is not null or undefined
            assertParamExists('questsQuestIdSubmissionsPut', 'submission', submission)
            const localVarPath = `/quests/{questId}/submissions`
                .replace(`{${"questId"}}`, encodeURIComponent(String(questId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestsApi - functional programming interface
 * @export
 */
export const QuestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsDelete(filter?: Quest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Quest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {QuestQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsGet($limit?: number, $skip?: number, filter?: QuestQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsGet($limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Quest to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Quest to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestPatch} questPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsIdPatch(id: number, questPatch: QuestPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsIdPatch(id, questPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestData} questData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsIdPut(id: number, questData: QuestData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsIdPut(id, questData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Quest} quest 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsPatch(quest: Quest, filter?: Quest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Quest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsPatch(quest, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsPostRequest} questsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsPost(questsPostRequest: QuestsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsPost(questsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Quest>} quest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsPut(quest: Array<Quest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Quest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsPut(quest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistDelete(questId: string, filter?: Registration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistDelete(questId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistGet(questId, $limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistIdDelete(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistIdGet(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistIdGet(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistIdPatch(questId, id, registrationPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistIdPut(questId: string, id: number, registrationData: RegistrationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistIdPut(questId, id, registrationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistPatch(questId: string, registration: Registration, filter?: Registration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistPatch(questId, registration, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistPost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsQuestIdEnlistPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistPost(questId, questsQuestIdEnlistPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdEnlistPut(questId: string, registration: Array<Registration>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdEnlistPut(questId, registration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdEnlistPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteDelete(questId: string, filter?: Registration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteDelete(questId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteGet(questId, $limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteIdDelete(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteIdGet(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteIdGet(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteIdPatch(questId, id, registrationPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInviteIdPut(questId: string, id: number, registrationData: RegistrationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInviteIdPut(questId, id, registrationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInviteIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInvitePatch(questId: string, registration: Registration, filter?: Registration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInvitePatch(questId, registration, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInvitePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInvitePost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsQuestIdEnlistPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInvitePost(questId, questsQuestIdEnlistPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdInvitePut(questId: string, registration: Array<Registration>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdInvitePut(questId, registration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdInvitePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsDelete(questId: string, filter?: QuestId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestIdList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsDelete(questId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsGet(questId: string, $limit?: number, $skip?: number, $sort?: object, filter?: QuestId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestIdPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsGet(questId, $limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsIdDelete(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsIdGet(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsIdGet(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsIdPatch(questId: string, id: number, body: QuestId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsIdPatch(questId, id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsIdPut(questId: string, id: number, body: QuestId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsIdPut(questId, id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} body 
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsPatch(questId: string, body: QuestId, filter?: QuestId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestIdList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsPatch(questId, body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId | QuestIdList} questIdQuestIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsPost(questId: string, questIdQuestIdList: QuestId | QuestIdList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsQuestIdReviewsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsPost(questId, questIdQuestIdList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestIdList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdReviewsPut(questId: string, body: QuestIdList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestIdList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdReviewsPut(questId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdReviewsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsDelete(questId: string, filter?: Submission, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsDelete(questId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsGet(questId: string, $limit?: number, $skip?: number, filter?: SubmissionQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsGet(questId, $limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsIdDelete(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsIdGet(questId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsIdGet(questId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsIdPatch(questId: string, id: number, submissionPatch: SubmissionPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsIdPatch(questId, id, submissionPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsIdPut(questId: string, id: number, submissionData: SubmissionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsIdPut(questId, id, submissionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsPatch(questId: string, submission: Submission, filter?: Submission, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsPatch(questId, submission, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsPost(questId: string, questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestsQuestIdSubmissionsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsPost(questId, questsQuestIdSubmissionsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questsQuestIdSubmissionsPut(questId: string, submission: Array<Submission>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Submission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questsQuestIdSubmissionsPut(questId, submission, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestsApi.questsQuestIdSubmissionsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestsApi - factory interface
 * @export
 */
export const QuestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestsApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsDelete(filter?: Quest, options?: any): AxiosPromise<Array<Quest>> {
            return localVarFp.questsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {QuestQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsGet($limit?: number, $skip?: number, filter?: QuestQuery, options?: any): AxiosPromise<QuestPagination> {
            return localVarFp.questsGet($limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of Quest to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdDelete(id: number, options?: any): AxiosPromise<Quest> {
            return localVarFp.questsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of Quest to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdGet(id: number, options?: any): AxiosPromise<Quest> {
            return localVarFp.questsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestPatch} questPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdPatch(id: number, questPatch: QuestPatch, options?: any): AxiosPromise<Quest> {
            return localVarFp.questsIdPatch(id, questPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of Quest to update
         * @param {QuestData} questData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsIdPut(id: number, questData: QuestData, options?: any): AxiosPromise<Quest> {
            return localVarFp.questsIdPut(id, questData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Quest} quest 
         * @param {Quest} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPatch(quest: Quest, filter?: Quest, options?: any): AxiosPromise<Array<Quest>> {
            return localVarFp.questsPatch(quest, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {QuestsPostRequest} questsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPost(questsPostRequest: QuestsPostRequest, options?: any): AxiosPromise<QuestsPostRequest> {
            return localVarFp.questsPost(questsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<Quest>} quest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsPut(quest: Array<Quest>, options?: any): AxiosPromise<Array<Quest>> {
            return localVarFp.questsPut(quest, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistDelete(questId: string, filter?: Registration, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdEnlistDelete(questId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: any): AxiosPromise<RegistrationPagination> {
            return localVarFp.questsQuestIdEnlistGet(questId, $limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdDelete(questId: string, id: number, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdEnlistIdDelete(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdGet(questId: string, id: number, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdEnlistIdGet(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdEnlistIdPatch(questId, id, registrationPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistIdPut(questId: string, id: number, registrationData: RegistrationData, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdEnlistIdPut(questId, id, registrationData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPatch(questId: string, registration: Registration, filter?: Registration, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdEnlistPatch(questId, registration, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: any): AxiosPromise<QuestsQuestIdEnlistPostRequest> {
            return localVarFp.questsQuestIdEnlistPost(questId, questsQuestIdEnlistPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdEnlistPut(questId: string, registration: Array<Registration>, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdEnlistPut(questId, registration, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteDelete(questId: string, filter?: Registration, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdInviteDelete(questId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {RegistrationQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: any): AxiosPromise<RegistrationPagination> {
            return localVarFp.questsQuestIdInviteGet(questId, $limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdDelete(questId: string, id: number, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdInviteIdDelete(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdGet(questId: string, id: number, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdInviteIdGet(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationPatch} registrationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdInviteIdPatch(questId, id, registrationPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Registration to update
         * @param {RegistrationData} registrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInviteIdPut(questId: string, id: number, registrationData: RegistrationData, options?: any): AxiosPromise<Registration> {
            return localVarFp.questsQuestIdInviteIdPut(questId, id, registrationData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Registration} registration 
         * @param {Registration} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePatch(questId: string, registration: Registration, filter?: Registration, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdInvitePatch(questId, registration, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: any): AxiosPromise<QuestsQuestIdEnlistPostRequest> {
            return localVarFp.questsQuestIdInvitePost(questId, questsQuestIdEnlistPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Registration>} registration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdInvitePut(questId: string, registration: Array<Registration>, options?: any): AxiosPromise<Array<Registration>> {
            return localVarFp.questsQuestIdInvitePut(questId, registration, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsDelete(questId: string, filter?: QuestId, options?: any): AxiosPromise<QuestIdList> {
            return localVarFp.questsQuestIdReviewsDelete(questId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsGet(questId: string, $limit?: number, $skip?: number, $sort?: object, filter?: QuestId, options?: any): AxiosPromise<QuestIdPagination> {
            return localVarFp.questsQuestIdReviewsGet(questId, $limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdDelete(questId: string, id: number, options?: any): AxiosPromise<QuestId> {
            return localVarFp.questsQuestIdReviewsIdDelete(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdGet(questId: string, id: number, options?: any): AxiosPromise<QuestId> {
            return localVarFp.questsQuestIdReviewsIdGet(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdPatch(questId: string, id: number, body: QuestId, options?: any): AxiosPromise<QuestId> {
            return localVarFp.questsQuestIdReviewsIdPatch(questId, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of :questId to update
         * @param {QuestId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsIdPut(questId: string, id: number, body: QuestId, options?: any): AxiosPromise<QuestId> {
            return localVarFp.questsQuestIdReviewsIdPut(questId, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId} body 
         * @param {QuestId} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPatch(questId: string, body: QuestId, filter?: QuestId, options?: any): AxiosPromise<QuestIdList> {
            return localVarFp.questsQuestIdReviewsPatch(questId, body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestId | QuestIdList} questIdQuestIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPost(questId: string, questIdQuestIdList: QuestId | QuestIdList, options?: any): AxiosPromise<QuestsQuestIdReviewsPostRequest> {
            return localVarFp.questsQuestIdReviewsPost(questId, questIdQuestIdList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestIdList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdReviewsPut(questId: string, body: QuestIdList, options?: any): AxiosPromise<QuestIdList> {
            return localVarFp.questsQuestIdReviewsPut(questId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsDelete(questId: string, filter?: Submission, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.questsQuestIdSubmissionsDelete(questId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {SubmissionQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsGet(questId: string, $limit?: number, $skip?: number, filter?: SubmissionQuery, options?: any): AxiosPromise<SubmissionPagination> {
            return localVarFp.questsQuestIdSubmissionsGet(questId, $limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdDelete(questId: string, id: number, options?: any): AxiosPromise<Submission> {
            return localVarFp.questsQuestIdSubmissionsIdDelete(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdGet(questId: string, id: number, options?: any): AxiosPromise<Submission> {
            return localVarFp.questsQuestIdSubmissionsIdGet(questId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionPatch} submissionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdPatch(questId: string, id: number, submissionPatch: SubmissionPatch, options?: any): AxiosPromise<Submission> {
            return localVarFp.questsQuestIdSubmissionsIdPatch(questId, id, submissionPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {number} id ID of Submission to update
         * @param {SubmissionData} submissionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsIdPut(questId: string, id: number, submissionData: SubmissionData, options?: any): AxiosPromise<Submission> {
            return localVarFp.questsQuestIdSubmissionsIdPut(questId, id, submissionData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Submission} submission 
         * @param {Submission} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPatch(questId: string, submission: Submission, filter?: Submission, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.questsQuestIdSubmissionsPatch(questId, submission, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {string} questId questId parameter
         * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPost(questId: string, questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: any): AxiosPromise<QuestsQuestIdSubmissionsPostRequest> {
            return localVarFp.questsQuestIdSubmissionsPost(questId, questsQuestIdSubmissionsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {string} questId questId parameter
         * @param {Array<Submission>} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questsQuestIdSubmissionsPut(questId: string, submission: Array<Submission>, options?: any): AxiosPromise<Array<Submission>> {
            return localVarFp.questsQuestIdSubmissionsPut(questId, submission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestsApi - object-oriented interface
 * @export
 * @class QuestsApi
 * @extends {BaseAPI}
 */
export class QuestsApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Quest} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsDelete(filter?: Quest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {QuestQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsGet($limit?: number, $skip?: number, filter?: QuestQuery, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsGet($limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of Quest to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of Quest to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Quest to update
     * @param {QuestPatch} questPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsIdPatch(id: number, questPatch: QuestPatch, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsIdPatch(id, questPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of Quest to update
     * @param {QuestData} questData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsIdPut(id: number, questData: QuestData, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsIdPut(id, questData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Quest} quest 
     * @param {Quest} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsPatch(quest: Quest, filter?: Quest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsPatch(quest, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {QuestsPostRequest} questsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsPost(questsPostRequest: QuestsPostRequest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsPost(questsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {Array<Quest>} quest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsPut(quest: Array<Quest>, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsPut(quest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Registration} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistDelete(questId: string, filter?: Registration, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistDelete(questId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {RegistrationQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistGet(questId, $limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistIdDelete(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistIdGet(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistIdGet(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to update
     * @param {RegistrationPatch} registrationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistIdPatch(questId, id, registrationPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to update
     * @param {RegistrationData} registrationData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistIdPut(questId: string, id: number, registrationData: RegistrationData, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistIdPut(questId, id, registrationData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Registration} registration 
     * @param {Registration} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistPatch(questId: string, registration: Registration, filter?: Registration, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistPatch(questId, registration, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistPost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistPost(questId, questsQuestIdEnlistPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Array<Registration>} registration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdEnlistPut(questId: string, registration: Array<Registration>, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdEnlistPut(questId, registration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Registration} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteDelete(questId: string, filter?: Registration, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteDelete(questId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {RegistrationQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteGet(questId: string, $limit?: number, $skip?: number, filter?: RegistrationQuery, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteGet(questId, $limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteIdDelete(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteIdGet(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteIdGet(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to update
     * @param {RegistrationPatch} registrationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteIdPatch(questId: string, id: number, registrationPatch: RegistrationPatch, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteIdPatch(questId, id, registrationPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Registration to update
     * @param {RegistrationData} registrationData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInviteIdPut(questId: string, id: number, registrationData: RegistrationData, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInviteIdPut(questId, id, registrationData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Registration} registration 
     * @param {Registration} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInvitePatch(questId: string, registration: Registration, filter?: Registration, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInvitePatch(questId, registration, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestsQuestIdEnlistPostRequest} questsQuestIdEnlistPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInvitePost(questId: string, questsQuestIdEnlistPostRequest: QuestsQuestIdEnlistPostRequest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInvitePost(questId, questsQuestIdEnlistPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Array<Registration>} registration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdInvitePut(questId: string, registration: Array<Registration>, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdInvitePut(questId, registration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestId} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsDelete(questId: string, filter?: QuestId, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsDelete(questId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {QuestId} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsGet(questId: string, $limit?: number, $skip?: number, $sort?: object, filter?: QuestId, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsGet(questId, $limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of :questId to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsIdDelete(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of :questId to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsIdGet(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsIdGet(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of :questId to update
     * @param {QuestId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsIdPatch(questId: string, id: number, body: QuestId, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsIdPatch(questId, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of :questId to update
     * @param {QuestId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsIdPut(questId: string, id: number, body: QuestId, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsIdPut(questId, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestId} body 
     * @param {QuestId} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsPatch(questId: string, body: QuestId, filter?: QuestId, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsPatch(questId, body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestId | QuestIdList} questIdQuestIdList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsPost(questId: string, questIdQuestIdList: QuestId | QuestIdList, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsPost(questId, questIdQuestIdList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestIdList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdReviewsPut(questId: string, body: QuestIdList, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdReviewsPut(questId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Submission} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsDelete(questId: string, filter?: Submission, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsDelete(questId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {SubmissionQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsGet(questId: string, $limit?: number, $skip?: number, filter?: SubmissionQuery, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsGet(questId, $limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Submission to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsIdDelete(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsIdDelete(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Submission to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsIdGet(questId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsIdGet(questId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Submission to update
     * @param {SubmissionPatch} submissionPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsIdPatch(questId: string, id: number, submissionPatch: SubmissionPatch, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsIdPatch(questId, id, submissionPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {number} id ID of Submission to update
     * @param {SubmissionData} submissionData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsIdPut(questId: string, id: number, submissionData: SubmissionData, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsIdPut(questId, id, submissionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Submission} submission 
     * @param {Submission} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsPatch(questId: string, submission: Submission, filter?: Submission, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsPatch(questId, submission, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {string} questId questId parameter
     * @param {QuestsQuestIdSubmissionsPostRequest} questsQuestIdSubmissionsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsPost(questId: string, questsQuestIdSubmissionsPostRequest: QuestsQuestIdSubmissionsPostRequest, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsPost(questId, questsQuestIdSubmissionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {string} questId questId parameter
     * @param {Array<Submission>} submission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestsApi
     */
    public questsQuestIdSubmissionsPut(questId: string, submission: Array<Submission>, options?: RawAxiosRequestConfig) {
        return QuestsApiFp(this.configuration).questsQuestIdSubmissionsPut(questId, submission, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScorecardsApi - axios parameter creator
 * @export
 */
export const ScorecardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsDelete: async (filter?: Scorecards, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scorecards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsGet: async ($limit?: number, $skip?: number, $sort?: object, filter?: Scorecards, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scorecards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($sort !== undefined) {
                localVarQueryParameter['$sort'] = $sort;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of scorecards to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scorecardsIdDelete', 'id', id)
            const localVarPath = `/scorecards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of scorecards to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scorecardsIdGet', 'id', id)
            const localVarPath = `/scorecards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdPatch: async (id: number, body: Scorecards, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scorecardsIdPatch', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('scorecardsIdPatch', 'body', body)
            const localVarPath = `/scorecards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdPut: async (id: number, body: Scorecards, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scorecardsIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('scorecardsIdPut', 'body', body)
            const localVarPath = `/scorecards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} body 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPatch: async (body: Scorecards, filter?: Scorecards, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('scorecardsPatch', 'body', body)
            const localVarPath = `/scorecards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Scorecards | ScorecardsList} scorecardsScorecardsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPost: async (scorecardsScorecardsList: Scorecards | ScorecardsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scorecardsScorecardsList' is not null or undefined
            assertParamExists('scorecardsPost', 'scorecardsScorecardsList', scorecardsScorecardsList)
            const localVarPath = `/scorecards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scorecardsScorecardsList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {ScorecardsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPut: async (body: ScorecardsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('scorecardsPut', 'body', body)
            const localVarPath = `/scorecards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScorecardsApi - functional programming interface
 * @export
 */
export const ScorecardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScorecardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsDelete(filter?: Scorecards, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScorecardsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsGet($limit?: number, $skip?: number, $sort?: object, filter?: Scorecards, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScorecardsPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsGet($limit, $skip, $sort, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of scorecards to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scorecards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of scorecards to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scorecards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsIdPatch(id: number, body: Scorecards, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scorecards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsIdPatch(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsIdPut(id: number, body: Scorecards, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scorecards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsIdPut(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} body 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsPatch(body: Scorecards, filter?: Scorecards, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScorecardsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsPatch(body, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Scorecards | ScorecardsList} scorecardsScorecardsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsPost(scorecardsScorecardsList: Scorecards | ScorecardsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScorecardsPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsPost(scorecardsScorecardsList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {ScorecardsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scorecardsPut(body: ScorecardsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScorecardsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scorecardsPut(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScorecardsApi.scorecardsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScorecardsApi - factory interface
 * @export
 */
export const ScorecardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScorecardsApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsDelete(filter?: Scorecards, options?: any): AxiosPromise<ScorecardsList> {
            return localVarFp.scorecardsDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {object} [$sort] Property to sort results
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsGet($limit?: number, $skip?: number, $sort?: object, filter?: Scorecards, options?: any): AxiosPromise<ScorecardsPagination> {
            return localVarFp.scorecardsGet($limit, $skip, $sort, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of scorecards to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdDelete(id: number, options?: any): AxiosPromise<Scorecards> {
            return localVarFp.scorecardsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of scorecards to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdGet(id: number, options?: any): AxiosPromise<Scorecards> {
            return localVarFp.scorecardsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdPatch(id: number, body: Scorecards, options?: any): AxiosPromise<Scorecards> {
            return localVarFp.scorecardsIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of scorecards to update
         * @param {Scorecards} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsIdPut(id: number, body: Scorecards, options?: any): AxiosPromise<Scorecards> {
            return localVarFp.scorecardsIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {Scorecards} body 
         * @param {Scorecards} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPatch(body: Scorecards, filter?: Scorecards, options?: any): AxiosPromise<ScorecardsList> {
            return localVarFp.scorecardsPatch(body, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {Scorecards | ScorecardsList} scorecardsScorecardsList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPost(scorecardsScorecardsList: Scorecards | ScorecardsList, options?: any): AxiosPromise<ScorecardsPostRequest> {
            return localVarFp.scorecardsPost(scorecardsScorecardsList, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {ScorecardsList} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scorecardsPut(body: ScorecardsList, options?: any): AxiosPromise<ScorecardsList> {
            return localVarFp.scorecardsPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScorecardsApi - object-oriented interface
 * @export
 * @class ScorecardsApi
 * @extends {BaseAPI}
 */
export class ScorecardsApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {Scorecards} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsDelete(filter?: Scorecards, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {object} [$sort] Property to sort results
     * @param {Scorecards} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsGet($limit?: number, $skip?: number, $sort?: object, filter?: Scorecards, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsGet($limit, $skip, $sort, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of scorecards to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of scorecards to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsIdGet(id: number, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of scorecards to update
     * @param {Scorecards} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsIdPatch(id: number, body: Scorecards, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsIdPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of scorecards to update
     * @param {Scorecards} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsIdPut(id: number, body: Scorecards, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {Scorecards} body 
     * @param {Scorecards} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsPatch(body: Scorecards, filter?: Scorecards, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsPatch(body, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {Scorecards | ScorecardsList} scorecardsScorecardsList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsPost(scorecardsScorecardsList: Scorecards | ScorecardsList, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsPost(scorecardsScorecardsList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {ScorecardsList} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorecardsApi
     */
    public scorecardsPut(body: ScorecardsList, options?: RawAxiosRequestConfig) {
        return ScorecardsApiFp(this.configuration).scorecardsPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete: async (filter?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {UserQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async ($limit?: number, $skip?: number, filter?: UserQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($limit !== undefined) {
                localVarQueryParameter['$limit'] = $limit;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of User to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of User to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserPatch} userPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPatch: async (id: number, userPatch: UserPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPatch', 'id', id)
            // verify required parameter 'userPatch' is not null or undefined
            assertParamExists('usersIdPatch', 'userPatch', userPatch)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut: async (id: number, userData: UserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPut', 'id', id)
            // verify required parameter 'userData' is not null or undefined
            assertParamExists('usersIdPut', 'userData', userData)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {User} user 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatch: async (user: User, filter?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('usersPatch', 'user', user)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                for (const [key, value] of Object.entries(filter)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {UsersPostRequest} usersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (usersPostRequest: UsersPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersPostRequest' is not null or undefined
            assertParamExists('usersPost', 'usersPostRequest', usersPostRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<User>} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPut: async (user: Array<User>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('usersPut', 'user', user)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDelete(filter?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {UserQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet($limit?: number, $skip?: number, filter?: UserQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPagination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet($limit, $skip, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of User to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of User to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserPatch} userPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPatch(id: number, userPatch: UserPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPatch(id, userPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPut(id: number, userData: UserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPut(id, userData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {User} user 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPatch(user: User, filter?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPatch(user, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {UsersPostRequest} usersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(usersPostRequest: UsersPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(usersPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<User>} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPut(user: Array<User>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPut(user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Removes multiple resources queried by given filters.
         * @summary 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete(filter?: User, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersDelete(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all resources from the service.
         * @summary 
         * @param {number} [$limit] Number of results to return
         * @param {number} [$skip] Number of results to skip
         * @param {UserQuery} [filter] Query parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet($limit?: number, $skip?: number, filter?: UserQuery, options?: any): AxiosPromise<UserPagination> {
            return localVarFp.usersGet($limit, $skip, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the resource with id.
         * @summary 
         * @param {number} id ID of User to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single resource with the given id from the service.
         * @summary 
         * @param {number} id ID of User to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserPatch} userPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPatch(id: number, userPatch: UserPatch, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdPatch(id, userPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the resource identified by id using data.
         * @summary 
         * @param {number} id ID of User to update
         * @param {UserData} userData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: number, userData: UserData, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdPut(id, userData, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources queried by given filters.
         * @summary 
         * @param {User} user 
         * @param {User} [filter] Query parameters to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatch(user: User, filter?: User, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersPatch(user, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new resource with data.
         * @summary 
         * @param {UsersPostRequest} usersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(usersPostRequest: UsersPostRequest, options?: any): AxiosPromise<UsersPostRequest> {
            return localVarFp.usersPost(usersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates multiple resources.
         * @summary 
         * @param {Array<User>} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPut(user: Array<User>, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersPut(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Removes multiple resources queried by given filters.
     * @summary 
     * @param {User} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDelete(filter?: User, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDelete(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all resources from the service.
     * @summary 
     * @param {number} [$limit] Number of results to return
     * @param {number} [$skip] Number of results to skip
     * @param {UserQuery} [filter] Query parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet($limit?: number, $skip?: number, filter?: UserQuery, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet($limit, $skip, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the resource with id.
     * @summary 
     * @param {number} id ID of User to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single resource with the given id from the service.
     * @summary 
     * @param {number} id ID of User to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of User to update
     * @param {UserPatch} userPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPatch(id: number, userPatch: UserPatch, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdPatch(id, userPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the resource identified by id using data.
     * @summary 
     * @param {number} id ID of User to update
     * @param {UserData} userData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPut(id: number, userData: UserData, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdPut(id, userData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources queried by given filters.
     * @summary 
     * @param {User} user 
     * @param {User} [filter] Query parameters to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPatch(user: User, filter?: User, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPatch(user, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new resource with data.
     * @summary 
     * @param {UsersPostRequest} usersPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(usersPostRequest: UsersPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPost(usersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates multiple resources.
     * @summary 
     * @param {Array<User>} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPut(user: Array<User>, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPut(user, options).then((request) => request(this.axios, this.basePath));
    }
}



