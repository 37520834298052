import React , {useState ,useEffect } from "react";
import {  Col, Card, Button, Dropdown ,ButtonGroup} from "react-bootstrap";

export const Filter = ({filter , setFilter , setSortStatus , setSortedBy , setSkip}) => {

	const phases = ["registration" , "submission","review" , "ended"];
	const types=["🛠️ Backend","🤖 Data Science","🛠️ DevOps","⚛ Frontend","🎮 Game Art","🎮 Game Dev","🎮 Game Design","🎮 Game Story","🚦QA","🖌️ UI/UX"];
  useEffect(()=>{
    setSortStatus("");
    setSortedBy("");
  }, [filter, setSortStatus, setSortedBy]);
  return (
    <>
        <span className="fs-22 px-2" style={{marginRight:"10px" , color:"#145650"}}>
			<i className="fa-solid fa-filter"></i>
      </span>
		<div style={{marginRight:"10px"}}>
        	<ButtonGroup>
                <Dropdown>
                    <Dropdown.Toggle className="me-2" variant="secondary">
                      {filter.phase || "Status"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
						{
							phases.map(val=><Dropdown.Item key={val} onClick={()=>{setSkip(0); setFilter({...filter , phase:val})}}>{val}</Dropdown.Item>)
						}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
		</div>
		{/* <div >
        	<ButtonGroup>
                <Dropdown>
                    <Dropdown.Toggle className="me-2" variant="light">
                      Type
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
						{
							types.map(val=><Dropdown.Item key={val} onClick={()=>setType(val)}>{val}</Dropdown.Item>)
						}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
		</div> */}
		<Button variant="tp-btn-light text-black" onClick={()=>setFilter({'$sort[id]': -1})}>Reset</Button>
		</>
  );
};
