import React from "react";

const Arrow = ({ direction }) => {
  return (
    <>
      {
        direction === "desc" ?<i className="fa-solid fa-sort-down"></i>: 
        direction === "asc" ?<i className="fa-solid fa-caret-up"></i> : 
        null
      }
    </>
  );
};

export default Arrow;
