import React, { useEffect, useRef, useState } from 'react'
import useQuestsAPI from '../../../../hooks/useQuestsAPI';
import { quests } from '../../../../services/api/models';
import { sortTable } from "../utils";
import {useSearchParams} from "react-router-dom";

const Pagination = ({setQuests , setIsLoading , skip , setSkip ,setFullData , sortedBy,sortStatus,setSortStatus,setSortedBy , filter }) => {
    const {isLoading , data } = useQuestsAPI(null,10,skip,filter);  //id , limit , skip
    const [total, setTotal] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

  // row number in page
  const row = 10;
  const activePag = useRef(0);
  if(skip === 0) activePag.current=0;
  // Active paggination & chage table data
  const onClick = (i) => {
    activePag.current = i;
    setSkip(i * row);
    window.scrollTo(0, 0);
    searchParams.delete('search');
  };
  
  useEffect(()=>{
    if (!isLoading && sortedBy) {      // to sort data when change page (asc/dec)
      const filteredData = sortTable(data.data,sortStatus, sortedBy);
      setQuests([])
     setQuests(filteredData);
     setFullData(data.data);
    }else if(!isLoading){    // to store origin data if not found sort status
      setSortedBy("");
      setSortStatus("");
     setQuests(data.data);
      setFullData(data.data)
      setTotal(data.total);
    }
    setIsLoading(isLoading)
  },[isLoading , data, data.total,setQuests, filter, setIsLoading,setSkip, setFullData, sortStatus, sortedBy,setSortedBy,setSortStatus])

  // Active paginarion
  let paggination = Array(Math.ceil(total / row))
    .fill()
    .map((_, i) => i + 1);


  return (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
    <div className='mb-md-0 mb-2'>
        <h5 className="mb-0">
            Showing {(total>0)?activePag.current * row + 1:0} to{" "}
            {total > (activePag.current + 1) * row
             ? (activePag.current + 1) * row
             : total}{" "}
            of {total} entries
        </h5>
    </div>
    <nav>
        <ul className="pagination pagination-circle justify-content-center">
            {/* Go To Previous Page */}
            <li className="page-item page-indicator">
                <button className="page-link" onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} >
                    <i className='fa fa-angle-double-left' />
                </button>
            </li>
            {/* Pages Number */}
            <li className="page-item page-indicator">
                <span style={{display:'flex'}}>
                    {paggination.map((number, i) => (
                        <button key={i} onClick={() => onClick(i)}
                         className={`mx-1 page-link ${activePag.current === i ? "text-white bg-primary" : ""} `}
                        >
                            {number}
                        </button>
                    ))}
                </span>
            </li>
            {/* Go To Next Page */}
            <li className="page-item page-indicator">
                <button className="page-link" onClick={() =>activePag.current + 1 < paggination.length && onClick(activePag.current + 1)}>
                    <i className='fa fa-angle-double-right' />
                </button>
            </li>
        </ul>
    </nav>
</div>	
  )
}

export default Pagination