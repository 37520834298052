import React, { useEffect, useRef } from 'react'

const PaginationFiltration = ({data , setQuests , sortStatus }) => {
  // row number in page
  const row = 7;
  const activePag = useRef(0);
  // change table data
  const chageData = (frist, sec) => {
    const pageData = data.slice(frist, sec);
    setQuests(pageData);
    // window.scrollTo(0, 0);
  };
  
  // initial table data from 0 to row
  useEffect(() => {
    const pageData = data.slice(0, row);
    setQuests(pageData);
  }, [sortStatus , data ,setQuests]);

  // Active paginarion
  let paggination = Array(Math.ceil(data.length / row))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage table data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * row, (activePag.current + 1) * row);
  };
  return (
    <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
    <div className='mb-md-0 mb-2'>
        <h5 className="mb-0">
            Showing {(data.length)?activePag.current * row + 1:0} to{" "}
            {data.length > (activePag.current + 1) * row
             ? (activePag.current + 1) * row
             : data.length}{" "}
            of {data.length} entries
        </h5>
    </div>
    <nav>
        <ul className="pagination pagination-circle justify-content-center">
            {/* Go To Previous Page */}
            <li className="page-item page-indicator">
                <button className="page-link" onClick={() => activePag.current > 0 && onClick(activePag.current - 1)} >
                    <i className='fa fa-angle-double-left' />
                </button>
            </li>
            {/* Pages Number */}
            <li className="page-item page-indicator">
                <span style={{display:'flex'}}>
                    {paggination.map((number, i) => (
                        <button key={i} onClick={() => onClick(i)}
                         className={`mx-1 page-link ${activePag.current === i ? "text-white bg-primary" : ""} `}
                        >
                            {number}
                        </button>
                    ))}
                </span>
            </li>
            {/* Go To Next Page */}
            <li className="page-item page-indicator">
                <button className="page-link" onClick={() =>activePag.current + 1 < paggination.length && onClick(activePag.current + 1)}>
                    <i className='fa fa-angle-double-right' />
                </button>
            </li>
        </ul>
    </nav>
</div>	
  )
}

export default PaginationFiltration