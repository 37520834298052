import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// we don't need to update through the auth0management api
//import authData from "../../services/AddAuthData.js";
import { useAuth0 } from "@auth0/auth0-react";
// image
import logo from "../../images/cq-small-logo.png";
import { auth, users } from "../../services/api/models";
import {handleErrorMessage, roleData, getValidationSchema } from "../../validation/profileData.validation";
import { useSelector } from "react-redux";
import {ToastContainer , toast} from "react-toastify";
import { useForm } from 'react-hook-form'; // Assuming you're using react-hook-form
import { yupResolver } from '@hookform/resolvers/yup';

function ProfileData(props) {
  const navigate = useNavigate();
  const divRef = useRef(null);
  const { user ,loginWithRedirect, getAccessTokenSilently , logout} = useAuth0();
  const acceptData = user.role ? true : false;
  // list data :
  const roles = ["client","reviewer", "contestant"];
  const studyYearList = ['1' , '2' , '3' , '4' , 'Graduate'];
  const jobStateList = ['Part time' , 'Full time' , 'Free hours' , 'Intern' , 'Other'];
  const fieldsInterestList = [ "Cyber Security" , "Game Development", "Game Art", "Game Design", "Devops / Cloud", 
                                "Backend", "Frontend", "QA (Testing)", "UI / UX","Mobile Dev (Cross Platform)", 
                                "Android","IOS", "Machine Learning","Model Data","Robotics","IOT","Other"];
  // states
  const [role, setRole] = useState("");
  const [studyYear, setStudyYear] = useState("");
  const [interestFields, setInterestFields] = useState([]);
  const [jobState, setJobState] = useState("");
  const [clickLoading , setClickLoading] = useState(false);
  const [openInterestFields , setOpenInterestFields] = useState(false);

  const validationSchema = getValidationSchema(true); // true: terms is required
  let { register, handleSubmit ,setValue, formState: { errors }} = useForm({
    resolver: yupResolver(validationSchema), // Integrate Yup validation
    defaultValues: {
      interestFields: []
    }
  });
  // console.log(errors)

  const onSubmit =  async(formData) => {
  console.log('Form data:', formData); // This will log the unvalidated data
  const newData = roleData(formData);
  const { sub , email} = user;
    const requestBody = { ...newData, avatar: user.picture, auth0Id: sub , email};
    // send request to update user data using SDK
    try{
      // setup headers
      let accessToken = await getAccessTokenSilently();
      console.log(accessToken)
      let headers = {Authorization: "Bearer " + accessToken }
      let Options = {headers:headers}
      console.log("USER BEFORE UPDATE: ", JSON.stringify(requestBody))
      const userFilter = {"auth0Id": user.sub};
      // update user data
      const {status, data} = await users.usersGet(1, 0, userFilter, Options);
      console.log(data)
      if(data.data.length === 0){
        const createdUser = await users.usersPost(requestBody, Options);
        console.log(createdUser)
      }
      else{
        const updatedUser = await users.usersPatch(requestBody, userFilter, Options);
        console.log(updatedUser)
      }
       console.log("DB USER IS HERE", data)

         if (status === 200) {
          toast.success("Success Sign Up");
         console.log("Success Add role , city and country to user");
         setTimeout(()=>{
          loginWithRedirect(); // to send new user data and new token
        },2000)
      }
      setClickLoading(false);
    }
    catch(err){
      if(err.response){
        const message = handleErrorMessage(err.response.data.message);
        toast.error(message);
      }else{
        toast.error(err.message || "Some Problems" );
      }
      console.log("Error in updating user data", err);
      setClickLoading(false);
    }
};

  // Handle Select Inputs
  const handleRoleChange = (e) => {
    const selectedRole = roles.find((role) => role === e.target.value);
    setRole(selectedRole);
  };
  const handleJobState = (e) => {
    const selectedJobState = jobStateList.find((job) => job === e.target.value);
    setJobState(selectedJobState);
  };
  const handleStudyYear = (e) => {
    const selectedYear = studyYearList.find((year) => year === e.target.value);
    setStudyYear(selectedYear);
  };
  const handleFieldsChange = (event) => {
    const value = event.target.value;
    let newInterestFields;
    if (event.target.checked) {
      newInterestFields = [...interestFields, value];
    } else {
      newInterestFields = interestFields.filter((field) => field !== value);
    }
    setInterestFields(newInterestFields);
    console.log(newInterestFields)
    setValue('interestFields', newInterestFields); 
  };
  function onLogout() {
    console.log("LOGOUT", process.env.REACT_APP_BASE_URL);
    logout({logoutParams: {
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: process.env.REACT_APP_BASE_URL
    }  
  });
  }
  // Handle click outside
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setOpenInterestFields(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // if success add new data to user not display form
  if (acceptData) {
    // login to refresh cached user data
    loginWithRedirect();
    navigate("/quest-list");
    return null;
  }

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2" style={{maxWidth:"47rem"}}>
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content position-relative " >
                <div
                  id="sign-in"
                  className="auth-form form-validation bg-white"
                  style={{ borderRadius: "2%", fontSize:"11px"}}
                >
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)} className="form-validate">
                    <div
                      style={{ height: "60px" }}
                      className=" mb-3 d-flex justify-content-center"
                    >
                    <button
                        type="button" onClick={onLogout}
                        className="btn-close" style={{height:"2em" , fontSize :"18px" , position:"absolute" , top:"2%" , right:"5%"}}
                      ></button>
                      <img src={logo} alt="logo" className="h-100" />
                    </div>
                    {/* <h3 className="text-center mb-4 text-black">Submit more Profile Data</h3> */}
                    <div className="form-group mb-3">
                        <label className="form-label font-w600">
                        Whatsapp Phone Number <span className="text-danger scale5 ms-2">*</span>
                        </label>
                        <input
                          {...register('whatsAppPhoneNumber')} 
                          type="text"
                          className="form-control mb-1"
                          placeholder="Type Your Whatsapp Phone"
                        />
                        {errors&& errors.whatsAppPhoneNumber && (<div className="text-danger fs-12"> {errors.whatsAppPhoneNumber.message} </div>)}
                      </div>
                    
                    <div className="form-group mb-3">
                      <label className="form-label font-w600">
                        Role<span className="text-danger scale5 ms-2">*</span>
                      </label>
                      <select
                        {...register('role')} 
                        value={role}
                        onChange={handleRoleChange}
                        className="form-select form-control solid mb-1"
                      >
                        <option value="" disabled>
                          Choose...
                        </option>
                        {roles.map((val, index) => (
                          <option key={index} value={val}>{val}</option>
                        ))}
                      </select>
                      {errors && errors.role && (<div className="text-danger fs-12"> {errors.role.message} </div>)} 
                    </div>
                    {
                      (role !== "" && role !== "client") && (
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                              <label className="form-label font-w600">
                              Nickname <span className="text-danger scale5 ms-2">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                placeholder="Type Your Nickname"
                                {...register('nickname')} 
                              />
                              {errors&& errors.nickname && (<div className="text-danger fs-12"> {errors.nickname.message} </div>)} 
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label font-w600">
                              Github Handle <span className="text-danger scale5 ms-2">*</span>
                              </label>
                              <input
                              {...register('githubHandle')} 
                                type="text"
                                className="form-control mb-1"
                                placeholder="Type Your Github Handle"
                              />
                              {errors&& errors.githubHandle && (<div className="text-danger fs-12"> {errors.githubHandle.message} </div>)}
                            </div>
                        </div>
                      )
                    }
                    
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label font-w600">Country <span className="text-danger scale5 ms-2">*</span></label>
                        <input
                          {...register('country')} 
                          type="text"
                          className="form-control mb-1"
                          placeholder="Type Your Country"
                        />
                        {errors&& errors.country && (<div className="text-danger fs-12"> {errors.country.message} </div>)}
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label font-w600">City <span className="text-danger scale5 ms-2">*</span></label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          placeholder="Type Your City"
                          {...register('city')} 
                          />
                        {errors&& errors.city && (<div className="text-danger fs-12"> {errors.city.message} </div>)}
                      </div>
                    </div>
                      <div className="form-group mb-3">
                      <label className="form-label font-w600">
                      Interest Fields<span className="text-danger scale5 ms-2">*</span>
                      </label>
                      <div className="form-control d-flex align-items-center mb-1" onClick={()=>setOpenInterestFields(!openInterestFields)}>
                        {interestFields.length === 0 ? 'Choose...': interestFields.join(', ')}
                      </div>
                      {openInterestFields &&
                       <div className="form-control h-100 p-4" ref={divRef}>
                       {fieldsInterestList.map((val, index) => (
                         <div key={index} className="form-check">
                           <input
                             type="checkbox"
                             value={val}
                             id={`checkbox-${index}`}
                             className="form-check-input"
                             checked={interestFields.includes(val)}
                             onChange={handleFieldsChange}
                           />
                           <label htmlFor={`checkbox-${index}`} className="form-check-label">
                             {val}
                           </label>
                         </div>
                       ))}
                     </div>
                      }
                      {errors&& errors.interestFields && (<div className="text-danger fs-12"> {errors.interestFields.message} </div>)}
                    </div>
                      {
                        (role !== "client" && role !== "")?
                        <>
                          <div className="form-group mb-3">
                            <label className="form-label font-w600">
                              Study Year<span className="text-danger scale5 ms-2">*</span>
                            </label>
                            <select
                              {...register('studyYear')} 
                              onChange={handleStudyYear}
                              value={studyYear}
                              className="form-select form-control solid mb-1"
                            >
                              <option value="" disabled>
                                Choose...
                              </option>
                              {studyYearList.map((val, index) => (
                                <option key={index} value={val}>{val}</option>
                              ))}
                            </select>
                            {errors && errors.studyYear && (<div className="text-danger fs-12">{errors.studyYear.message}</div>)} 
                          </div>
                        </>
                        : null
                      }
                      {
                       (role !== "client" && role !== "" && studyYear !== "Graduate" && studyYear !== "") ?
                        
                          <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label font-w600">
                          University <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <input
                            {...register('university')} 
                            type="text"
                            className="form-control mb-1"
                            placeholder="Type Your University"
                          />
                          {errors&& errors.university && (<div className="text-danger fs-12"> {errors.university.message} </div>)}
                            </div>
                           
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label font-w600">
                          Faculty <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <input
                            {...register('faculty')} 
                            type="text"
                            className="form-control mb-1"
                            placeholder="Type Your Faculty"
                          />
                          {errors&& errors.faculty && (<div className="text-danger fs-12"> {errors.faculty.message} </div>)}
                        </div>
                      </div>
                        :null
                      }        
                      {
                        (role === 'client')?
                        <div className="form-group mb-3">
                          <label className="form-label font-w600">
                          Company Name <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="Type Your Coumpany"
                            {...register('companyName')} 
                          />
                          {errors&& errors.companyName && (<div className="text-danger fs-12"> {errors.companyName.message} </div>)}
                            
                        </div>
                        
                        :null
                      }
                       {
                        (studyYear === "Graduate" && role !== "client")?
                        <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label font-w600">
                          Company Name <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <input
                            {...register('companyName')} 
                            type="text"
                            className="form-control mb-1"
                            placeholder="Type Your Coumpany"
                          />
                          {errors&& errors.companyName && (<div className="text-danger fs-12"> {errors.companyName.message} </div>)}
                            
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label font-w600">
                          Experience Years <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <input
                            {...register('experienceYears')} 
                            type="text"
                            className="form-control mb-1"
                            placeholder="Type Your Experience"
                          />
                          {errors&& errors.experienceYears && (<div className="text-danger fs-12"> {errors.experienceYears.message} </div>)}
                        </div>
                        <div className="form-group mb-3 col-md-4">
                      <label className="form-label font-w600">
                        Job State<span className="text-danger scale5 ms-2">*</span>
                      </label>
                          <select
                          {...register('jobState')} 
                        onChange={handleJobState}
                        value={jobState}
                        className="form-select form-control solid mb-1"
                      >
                        <option value="" disabled>
                          Choose...
                        </option>
                        {jobStateList.map((val, index) => (
                          <option key={index} value={val}>{val}</option>
                        ))}
                      </select>
                      {errors&& errors.jobState && (<div className="text-danger fs-12"> {errors.jobState.message} </div>)}

                    </div>
                      </div>   
                        :null
                      }
                      <div className="form-check mx-1">
                           <input
                             {...register('terms')} 
                             type="checkbox"
                             id={`checkbox-1`}
                             className="form-check-input"
                             
                           />
                           <label htmlFor={`checkbox-1`} className="form-check-label">
                            By signing up, you agree to 
                            {<a href=" https://code-quests.com/privacy-policy-2/" target="_blank" className="text-primary"> the terms of service </a>}
                            of code-quests.com
                           </label>
                           {errors&& errors.terms && (<div className="text-danger fs-12"> {errors.terms.message} </div>)}
                         </div>
                    <div className="text-center form-group mt-4">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={clickLoading} // Disable button while loading
                      >
                        {clickLoading ? (
                          <>
                            Loading
                            <span className="spinner-grow spinner-grow-sm" style={{ marginLeft: "5px" }} role="status" aria-hidden="true"></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <>Continue</>
                        )}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default ProfileData;
